import masterFakeEndpoint from "../../structure";

// Simulate the create-merchant-location API
export async function fakeCreateMerchantLocation(data) {
  console.log(data)
  // Define the expected data structure for this endpoint
  const dataStructure = {
    name: { type: "string", required: true },
    address: { 
      type: "object", 
      required: true, 
      structure: {
        address: { type: "string", required: true },
        city: { type: "string", required: true },
        state: { type: "string", required: true },
        zip_code: { type: "string", required: true },
      }
    },
    description: { type: "string", required: true },
    type: { type: "string", required: true },
    number: { type: "string", required: true },
    website: { type: "string", required: true },
    email: { type: "string", required: true },
    storePhoto: { type: "string", required: false },
  };

  // Define potential errors
  const errorArray = [

    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    id: Math.floor(Math.random() * 10000), // Simulate a random unique ID
    name: data.name,
    address: `${data.address.address}, ${data.address.city}, ${data.address.state}, ${data.address.zip_code}`,
    storePhoto: data.storePhoto || "https://via.placeholder.com/650", // Default photo if none provided
    description: data.description || "",
    type: data.type || "Unknown",
    number: data.number || "N/A",
    website: data.website || "N/A",
    email: data.email || "N/A",
    status: "Active", // Simulated status
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput,
    { successStatusCode: 201 } // Set the success status code to 201 Created
  );
}
