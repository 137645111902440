import React from "react";
import Button from "@mui/material/Button";
import { Icon } from "@mui/material";

const IconButton = ({ iconName, label, onClick, disabled, importedSx, fullWidth }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      fullWidth={fullWidth}
      startIcon={<Icon>{iconName}</Icon>}

      disabled={disabled}
      sx={{
        ...importedSx,
        mt: 1,
        mb: 1,
        
        fontFamily: "SF Pro Display, Arial, sans-serif",
   
        backgroundColor: "#44C952",
        color: "#ffffff",
        "&:hover": {
          backgroundColor: "#3da843",
        },
        "&:disabled": {
          backgroundColor: "#aeaeae",
          color: "#ffffff",
        },
        borderRadius: "4px",
      }}
    >
      {label}
    </Button>
  );
};

export default IconButton;
