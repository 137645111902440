import React from "react";

import { Box } from "@mui/material";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import CustomInputDialog from "../../../../components/molecules/dialogues/customInput";

const ConfirmCodeDialogUI = ({
  type,
  open,
  handleClose,
  credential,
  otp,
  errors,
  setCredential,
  setOtp,
  handleSubmit,
}) => {
  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title="Verification"
      subTitle="You need to enter the OTP code and part of your credentials to reset."
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {type === "password" && (
          <GeneralInput
            type="text"
            label="Current Username"
            value={credential}
            onChange={(e) => setCredential(e.target.value)}
            fullWidth
            error={!!errors.credential}
            errorMessageAndhelp={errors.credential || ""}
          />
        )}
        {type === "username" && (
          <GeneralInput
            type="password"
            label="Current Password"
            value={credential}
            onChange={(e) => setCredential(e.target.value)}
            fullWidth
            error={!!errors.credential}
            errorMessageAndhelp={errors.credential || ""}
          />
        )}
        <GeneralInput
          type="text"
          label="OTP"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          fullWidth
          error={!!errors.otp}
          errorMessageAndhelp={errors.otp || ""}
        />
      </Box>
    </CustomInputDialog>
  );
};

export default ConfirmCodeDialogUI;
