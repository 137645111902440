import React from "react";
import { Typography, Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const Header = ({ title, description, onBackClick, backArrow }) => {
  return (
    <Box sx={{
      display: 'flex', // Use flex to center the content box
      justifyContent: 'center', // Center the inner box horizontally in the container
      width: '100%', // Ensure the outer box takes full width to center content inside it
    }}>
      <Box sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
      }}>
        {/* Conditional rendering of back arrow inside the inner box */}
        {backArrow && (
          <IconButton
            aria-label="back"
            onClick={onBackClick}
            sx={{
              position: 'absolute',
              left: 0, // Position the back arrow inside the inner box
              top: 0, // Adjust top position if necessary
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography variant="h5" gutterBottom component="h1">
          {title}
        </Typography>
        <Typography variant="body1" sx={{
          textAlign: 'center',
          color: 'text.secondary',
          mb: 2,
        }}>
          {description}
        </Typography>
      </Box>
    </Box>
  );
};

export default Header;
