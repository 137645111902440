import React from 'react';
import { useDispatch } from 'react-redux';
import { TextField, InputAdornment, IconButton, MenuItem } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { showDialog } from '../../../../../features/dialog/dialogSlice';


const DocumentTypeField = ({ selectedType, handleDocumentTypeChange, document }) => {
  const dispatch = useDispatch();



  return (
    <TextField
      select
      label="Document Type"
      value={selectedType}
      onChange={handleDocumentTypeChange}
      fullWidth
      variant="outlined"
      sx={{ mb: 2 }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => dispatch(showDialog({ dialogType: 'info', message: document.helpText, title: "Document Type Help" }))}
             sx={{ mr: "6px" }}>
              <HelpOutlineIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    >
      {document.types.map((type, index) => (
        <MenuItem key={index} value={type}>{type}</MenuItem>
      ))}
    </TextField>
  );
};

export default DocumentTypeField;
