import React from 'react';
import { Drawer, List, Box, Typography, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const tabs = [
  { id: 1, text: "Dashboard", route: "/dashboard", Icon: DashboardIcon, section: "Main" },
  { id: 3, text: "Transactions", route: "/transactions", Icon: SwapHorizIcon, section: "Financial" },
  { id: 4, text: "Cashback", route: "/cashback", Icon: MonetizationOnIcon, section: "Financial" },
  { id: 5, text: "Register", route: "/register", Icon: AppRegistrationIcon, section: "Account" },
  { id: 2, text: "Bank", route: "/bank", Icon: AccountBalanceIcon, section: "Financial" },
  { id: 6, text: "Profile", route: "/profile", Icon: AccountCircleIcon, section: "Account" },
  { id: 7, text: "Settings", route: "/settings", Icon: SettingsIcon, section: "Account" },
];

const SideNavComponent = ({ drawerOpen, onTabClick }) => {
  const sections = tabs.reduce((acc, tab) => {
    acc[tab.section] = acc[tab.section] || [];
    acc[tab.section].push(tab);
    return acc;
  }, {});

  return (
    <Drawer
      variant="permanent"
      open={drawerOpen}
      sx={{
        width: drawerOpen ? 240 : 0,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerOpen ? 240 : 0,
          boxSizing: "border-box",
          backgroundColor: "#133C17",
          color: "white",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          justifyContent: "center",
          height: "75px",
          borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
          paddingX: "20px",
          marginBottom: "16px",
        }}
      >
        <Typography variant="h4" noWrap component="div" sx={{ fontWeight: "600", color: "#44C952" }}>
          Clavaa
        </Typography>
      </Box>
      {Object.entries(sections).map(([sectionName, sectionTabs], index) => (
        <React.Fragment key={index}>
          <Box sx={{ paddingLeft: "20px", paddingTop: "14px", marginBottom: -0.5 }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              sx={{
                color: "rgba(255, 255, 255, 0.7)",
                textTransform: "uppercase",
                fontSize: "0.80rem",
                fontWeight: 'bold',
                letterSpacing: "0.1em",
              }}
            >
              {sectionName}
            </Typography>
          </Box>
          <List>
            {sectionTabs.map((tab) => (
              <ListItem
                button
                key={tab.id}
                onClick={() => onTabClick(tab.route)}
                sx={{
                  width: "95%",
                  margin: "auto",
                  mb: 1,
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.08)",
                  },
                }}
              >
                <ListItemIcon sx={{ color: "inherit" }}>
                  <tab.Icon />
                </ListItemIcon>
                <ListItemText primary={tab.text} primaryTypographyProps={{ fontSize: '0.85rem' }} />
              </ListItem>
            ))}
          </List>
        </React.Fragment>
      ))}
      <Box sx={{ marginTop: "auto", padding: "10px" }}>
        <ListItem
          button
          onClick={onTabClick('/logout')}
          sx={{
            borderRadius: 2,
            "&:hover": {
              backgroundColor: "rgba(255, 255, 255, 0.08)",
            },
          }}
        >
          <ListItemIcon sx={{ color: "white" }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" sx={{ color: "white" }} primaryTypographyProps={{ fontSize: '0.875rem' }} />
        </ListItem>
      </Box>
    </Drawer>
  );
};

export default SideNavComponent;
