import React from "react";

import MainLayout from "../../../../components/pageStructures/dashboard/main";
import SettingsContainer from "./../organisms/settingsContainer"
const SettingsPage = () => {
  return (
    <MainLayout centerContent={false}>
      <SettingsContainer />
    </MainLayout>
  );
};

export default SettingsPage;
