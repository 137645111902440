// CustomCard.js
import React from 'react';
import { Card, CardContent } from '@mui/material';

const CustomCard = ({ children, sx }) => {
  return (
    <Card
      sx={{
        minWidth: 375,
        maxWidth: 470,
        boxShadow: 3,
        borderRadius: 2,
        height: 320,
        m: 1,
        position: "relative",
        ...sx,  // Allows for extending or overriding default styles
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "80%",
          textAlign: "center",
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default CustomCard;
