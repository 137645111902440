import React, { useState } from "react";

import LocationDetailsDialog from "./details";
import ManagerComponent from "./addComponents/manager";
import useFetchFeedback from "../../functions/apiFetchers/fullPageLoaders";
import { useDispatch } from "react-redux";
import MainLayout from "../../components/pageStructures/dashboard/main";
import {
  addStoreLocation,
  deleteStoreLocation,
} from "../../features/stores/storesSlice";
import StoreProfilesComponent from "./locations";
import { showAlert } from "../../features/alerts/alertSlice";

const ProfilePage = () => {
  const [isLocationDialogOpen, setIsLocationDialogOpen] = useState(false);
  const [isAddLocationDialogOpen, setIsAddLocationDialogOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const dispatch = useDispatch();
  const fetchFeedback = useFetchFeedback();

  const handleConfirmDelete = async (locationId) => {
    setIsLocationDialogOpen(false);
    await fetchFeedback({
      endpoint:`merchant/locations/${locationId}/`,
      loaderMessage: "Deleting location...",
      successMessage: "Location Deleted successfully!",
      requestType: "DELETE",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
        dispatch(deleteStoreLocation(locationId));
        
      },
  
    });
  };

  const createLocation = async (formData) => {
    console.log("pressed")
    setIsAddLocationDialogOpen(false);
    await fetchFeedback({
      endpoint:`merchant/locations/`,
      data: {...formData},
      loaderMessage: "Saving location details...",
      successMessage: "Location details saved successfully!",
      requestType: "POST",
      loaderType: "dialogueLoader",
      onSuccess: (responseData) => {
        dispatch(addStoreLocation(responseData));
        
      },
      
     

    });
  };

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setIsLocationDialogOpen(true);
  };

  return (
    <>
      <MainLayout>
        <StoreProfilesComponent
          handleLocationClick={handleLocationClick}
          setIsAddLocationDialogOpen={() => setIsAddLocationDialogOpen(true)}
        />
        {isAddLocationDialogOpen && (
          <ManagerComponent
            createLocation={createLocation}
            onClose={() => setIsAddLocationDialogOpen(false)}
          />
        )}
        {isLocationDialogOpen && (
          <LocationDetailsDialog
            isOpen={isLocationDialogOpen}
            onClose={() => setIsLocationDialogOpen(false)}
            location={selectedLocation}
            handleConfirmDelete={handleConfirmDelete}
          />
        )}
      </MainLayout>
    </>
  );
};

export default ProfilePage;
