/**
 * Updates the owner data list with new form data.
 * If an owner with the given ownerId exists, update the owner's data.
 * If no such owner exists, create a new owner entry with the given data.
 *
 * @param {Array} dataList - List of existing owners.
 * @param {Object} formData - New data to update.
 * @param {String} ownerId - ID of the owner to update.
 * @param {Boolean} newSetDataBoolean - Flag indicating the data structure type.
 * @returns {Array} - Updated list of owners.
 */
export const updateOwnerData = (dataList, formData, ownerId, newSetDataBoolean = false) => {
    let updatedList = [...dataList]; // Ensure immutability
    let ownerExists = false;

    // Iterate over the existing owners to find the one with the given ownerId
    for (let i = 0; i < updatedList.length; i++) {
        if (newSetDataBoolean) {
            if (updatedList[i]['id'] === ownerId) {
                ownerExists = true;
                // Update the existing owner's data with new formData
                Object.entries(formData).forEach(([displayName, value]) => {
                    const serverName = Object.keys(value)[0];
                    updatedList[i][serverName] = value[serverName];
                });
                break;
            }
        } else {
            if (updatedList[i]['Id'].id === ownerId) {
                ownerExists = true;
                // Update the existing owner's data with new formData
                Object.entries(formData).forEach(([displayName, value]) => {
                    const serverName = Object.keys(value)[0];
                    updatedList[i][displayName] = { [serverName]: value[serverName] };
                });
                break;
            }
        }
    }

    // If the owner does not exist, create a new owner entry
    if (!ownerExists) {
        let newOwner;
        if (newSetDataBoolean) {
            newOwner = { 'id': ownerId };
        } else {
            newOwner = { 'Id': { 'id': ownerId } };
        }
        Object.entries(formData).forEach(([displayName, value]) => {
            const serverName = Object.keys(value)[0];
            if (newSetDataBoolean) {
                newOwner[serverName] = value[serverName];
            } else {
                newOwner[displayName] = { [serverName]: value[serverName] };
            }
        });
        updatedList.push(newOwner);
    }

    return updatedList;
};