import masterFakeEndpoint from "../structure";

// Simulate the send-merchant-email-otp API
export async function fakeSendMerchantEmailOTP(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    email: { type: "string", required: true },
  };

  // Define potential errors
  const errorArray = [
    { error: { email: "This email has already been registered" }, status: 400 },
    { error: { error: "An error occured on our end" }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    profile_pk: Math.floor(Math.random() * 10000) + 1, // Simulate a profile primary key
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
