import React from 'react';
import { Box, CircularProgress, Typography, useTheme } from '@mui/material';

const InitialLoadingPage = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        textAlign: 'center',
        animation: 'fadeIn 1s ease-in-out',
      }}
    >
      <Box sx={{ mb: 2 }}>
        <CircularProgress
          size={80}
          thickness={4}
          sx={{
            color: '#44C952',
            mb: 2,
          }}
        />
      </Box>

      <Typography
        variant="h4"
        sx={{
          color: '#44C952',
          fontWeight: 'bold',
          letterSpacing: '0.1em',
        }}
      >
        Clavaa
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          color: theme.palette.text.secondary,
          mt: 1,
          maxWidth: '80%',
        }}
      >
        Preparing your personalized experience...
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          bottom: theme.spacing(4),
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: theme.palette.text.disabled,
          }}
        >
          © {new Date().getFullYear()} Clavaa. All rights reserved.
        </Typography>
      </Box>

      <style>
        {`
          @keyframes fadeIn {
            0% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}
      </style>
    </Box>
  );
};

export default InitialLoadingPage;
