import masterFakeEndpoint from "../structure";

// Simulate the create-business-info API
export async function fakeCreateBusinessInfo(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    business_name: { type: "string", required: true },
    business_classification: { type: "string", required: true },
    business_classification_id: { type: "string", required: true },
    business_structure: { type: "string", required: true },
    ein: { type: "string", required: false },
    address: {
      type: "object",
      required: true,
      structure: {
        address: { type: "string", required: true },
        city: { type: "string", required: true },
        state: { type: "string", required: true },
        zip_code: { type: "string", required: true },
      },
    },
    doing_business_as: { type: "string", required: false },
    website: { type: "string", required: false },
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    service: "Business profile has been created and submitted to Dwolla.",
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
