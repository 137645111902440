import { generateValidator } from "../../../../functions/validators/generateValidator";

const validateLocationDetails = generateValidator([
  { field: 'name', type: 'content', required: true, customMessage: 'Name is required.' },
  { field: 'number', type: 'phoneNumber', required: true },
  { field: 'email', type: 'email', required: true },
  { field: 'website', type: 'website', required: true },
  { field: 'address', type: 'address', required: true },
  { field: 'description', type: 'content', required: true, customMessage: 'Description is required.' },
  { field: 'type', type: 'content', required: true, customMessage: 'Store type is required unless it is "other".' },
  { field: 'customType', type: 'content', required: (formData) => formData.type === 'Other' }
]);

export default validateLocationDetails;
