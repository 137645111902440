import React, { useState } from "react";
import AddLocationDialog from "./add";
import UploadStorePhotoDialog from "./photo";

import { formatData } from "./functions/formatting";

const ManagerComponent = ({ createLocation, onClose }) => {
  const [state, setState] = useState({

      name: "",
      number: "",
      email: "",
      website: "",
      description: "",
      type: "",
      customType: "",
      address: {
        address: "",
        city: "",
        state: "",
        zip_code: "",
      },

    selectedPhoto: null,
    useDefaultPhoto: false,
    openUploadPhoto: false,
  });
  const [openUploadPhoto, setOpenUploadPhoto] = useState(false);

  const Apisubmission = () => {
   

    createLocation(state);
  };

  const setFormData = (newFormData) => {
    setState((prevState) => ({
      ...prevState,
      ...newFormData,
    }));
  };

  const setSelectedPhoto = (photo) => {
    setState((prevState) => ({
      ...prevState,
      selectedPhoto: photo,
    }));
  };

  const setUseDefaultPhoto = (value) => {
    setState((prevState) => ({
      ...prevState,
      useDefaultPhoto: value,
    }));
  };


  return (
    <>
      {!openUploadPhoto ? (
        <AddLocationDialog
          open={true}
          onClose={onClose}
          onAdd={() => setOpenUploadPhoto(true)}
          formData={state}
          setFormData={setFormData}
        />
      ) : (
        <UploadStorePhotoDialog
          open={true}
          onClose={onClose}
          onSubmit={Apisubmission}
          onBack={() => setOpenUploadPhoto(false)}
          selectedPhoto={state.selectedPhoto}
          setSelectedPhoto={setSelectedPhoto}
          useDefaultPhoto={state.useDefaultPhoto}
          setUseDefaultPhoto={setUseDefaultPhoto}
        />
      )}
    </>
  );
}
export default ManagerComponent;