import React from "react";
import {
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const ContactSupportPanelUI = () => {
    return(
        <Box
        sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%", // Adjust as needed
        mt: 2,
        }}
    >
        <Typography
        variant="subtitle2"
        sx={{ fontWeight: 700, mb: 1, display: "flex", alignItems: "center" }}
        >
        Need Help?
        
        <Tooltip title="Get in touch with our support team for any help or questions you might have."></Tooltip>
        </Typography>
        <Typography variant="body1">
        For any questions, email us at
        <a href="mailto:help@clavaa.com" style={{ marginLeft: "5px" }}>
            help@clavaa.com
        </a>
        .
        </Typography>
    </Box>
  )
}

export default ContactSupportPanelUI;