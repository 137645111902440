import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Import components
import VerificationPage from "./pages/signUp/verification/main";
import AccountInfoPage from "./pages/signUp/account/main";
import EmailCodePage from "./pages/signUp/emailCode/main";
import BankPage from "./pages/bank/main";
// import Cashback from "./pages/cashback/main";
import ProfilePage from "./pages/profile/main";
import Register from "./pages/register/main";
import SettingsPage from "./pages/settings/combined/page/settingPage.js";
import { Provider } from "react-redux";
import store from "./app/store";
import ReduxComponents from "./reduxComponents/main";
import DashboardPage from "./pages/dashboard/main";
import EmailPage from "./pages/signUp/email/main";
import PersonalInfoPage from "./pages/signUp/pesonal&owner/main";
import BusinessInfoPage from "./pages/signUp/business/main";
import DocumentStatusList from "./pages/signUp/verification/documents/documentManager.js";
import CustomerRetryInfo from "./pages/signUp/verification/retry/manager.js";
import MainRouteGuard from "./functions/auth/routeGuards/mainRouteGuard.js";
import MidAuthRouteGuard from "./functions/auth/routeGuards/midAuth.js";
import ProfilePkCheckRouteGuard from "./functions/auth/routeGuards/profile_pkSignUp.js";
import RetryDataCheckRouteGuard from "./functions/auth/routeGuards/retryVerify.js";
import DocumentCheckRouteGuard from "./functions/auth/routeGuards/documentVerify.js";
import TransactionsPage from "./pages/transactions/combined/page/main.js";
import SignInPage from "./pages/signInUser/combined/page/AccountInfoPage.js";
import CashbackPage from "./pages/cashback/combined/page/cashbackPage.js";
const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <ReduxComponents />
        <Routes>
          <Route
            path="/bank"
            element={
              <MainRouteGuard>
                <BankPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/cashback"
            element={
              <MainRouteGuard>
                <CashbackPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/dashboard"
            element={
              <MainRouteGuard>
                <DashboardPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/profile"
            element={
              <MainRouteGuard>
                <ProfilePage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/register"
            element={
              <MainRouteGuard>
                <Register />
              </MainRouteGuard>
            }
          />
          <Route
            path="/settings"
            element={
              <MainRouteGuard>
                <SettingsPage />
              </MainRouteGuard>
            }
          />
          <Route
            path="/transactions"
            element={
              <MainRouteGuard>
                <TransactionsPage />
              </MainRouteGuard>
            }
          />
  <Route
        path="/personal-info"
        element={
          <MidAuthRouteGuard requiredStatus="personalInfo">
            <PersonalInfoPage />
          </MidAuthRouteGuard>
        }
      />
      <Route
        path="/business-info"
        element={
          <MidAuthRouteGuard requiredStatus="businessInfo">
            <BusinessInfoPage />
          </MidAuthRouteGuard>
        }
      />
      <Route
        path="/status"
        element={
          <MidAuthRouteGuard requiredStatus="verify">
            <VerificationPage />
          </MidAuthRouteGuard>
        }
      />
<Route
        path="/code-verification"
        element={
          <ProfilePkCheckRouteGuard>
            <EmailCodePage />
          </ProfilePkCheckRouteGuard>
        }
      />
      <Route
        path="/account-info"
        element={
          <ProfilePkCheckRouteGuard>
            <AccountInfoPage />
          </ProfilePkCheckRouteGuard>
        }
      />
      <Route
        path="/status/retry"
        element={
          <RetryDataCheckRouteGuard>
            <CustomerRetryInfo />
          </RetryDataCheckRouteGuard>
        }
      />
      <Route
        path="/status/document"
        element={
          <DocumentCheckRouteGuard>
            <DocumentStatusList />
          </DocumentCheckRouteGuard>
        }
      />
          <Route
            path="/sign-in"
            element={
                <SignInPage />
            }
          />
          <Route
            path="/email"
            element={
                <EmailPage />
            }
          />
        </Routes>
      </Router>
    </Provider>
  );
};
export default App;