import React from "react";

import Typography from "@mui/material/Typography";

import CustomCard from "./components/card";
import InfoIconButton from "../../components/molecules/dialogues/info";
import { selectPendingBalance } from "../../features/banking/bankSelectors";
import { useSelector } from "react-redux";

const Pending = () => {
  const pendingBalance = useSelector(selectPendingBalance);

  return (
    <CustomCard>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Pending Balance
      </Typography>
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        ${pendingBalance}
      </Typography>
      <Typography variant="body1" sx={{ width: "50%", textAlign: "center" }}>
        This amount will be deposited into the merchant's account soon.
      </Typography>
      <InfoIconButton
        title={"More About Pending Balance"}
        info={
          "The pending balance represents funds from transactions that have been authorized but not yet settled. These funds are expected to be deposited into the merchant's account shortly, following the standard clearing process. This mechanism ensures transactions are valid and funds are properly allocated."
        }
      />
    </CustomCard>
  );
};

export default Pending;
