

import React, { useState } from 'react';
import OwnerInfoForm from './ownerInfoForm';
import OwnerManager from './ownerManager';


// Accept the shared state and functions as props
const OwnerApplication = ({
  owners,
  setOwners,
  navigateBackToController,
  controllerIndex,
  handleSubmit,
 
}) => {
    const [showForm, setShowForm] = useState("");
    const [activeOwner, setActiveOwner] = useState("");

  const handleAdd = () => {
    setActiveOwner("");
    setShowForm(true);
  };



  const handleOwnerClick = (id) => {
    // Prevent selecting the controller as the active owner for editing
    if (id === controllerIndex) {
      return; // Early return to stop the function if trying to edit the controller
    }
  
    const owner = owners.find((owner) => owner.id === id);
    if (owner) {
      setActiveOwner({ ...owner });
      setShowForm(true);
    }
  };
  


  const handleDelete = (id) => {
    // Prevent deleting the controller
    if (id === controllerIndex) {
      return; // Early return to stop the function if trying to delete the controller
    }
    // Proceed with deletion if not the controller
    setOwners(currentOwners => currentOwners.filter(owner => owner.id !== id));

  };
  
 

  return (
    <>
      {showForm ? (
        <OwnerInfoForm
          activeOwner={activeOwner}
          handleSubmit={() => {setShowForm(false);}}
          navigateBackToOwnerManager={() => {setShowForm(false);}}
          setOwners={setOwners}
        />
       
      ) : (
        <OwnerManager
         owners={owners.map(owner => ({
            id: owner.id,
            name: `${owner.first_name} ${owner.last_name}`
          }))}
          onAdd={handleAdd}
          onDelete={handleDelete}
          onOwnerClick={handleOwnerClick}
          navigateBackToController={navigateBackToController}
          handleSubmit={handleSubmit}
          
        />
      )}
    </>
  );
};

export default OwnerApplication;
