import { checkForErrors } from "./structureFunctions/checkForErrors";
import { handleNullData } from "./structureFunctions/handleNullData";
import { simulateApiDelay } from "./structureFunctions/simulateApiDelay";
import { validateData } from "./structureFunctions/validateData";

/**
 * masterFakeEndpoint
 *
 * This function simulates a fake API endpoint that validates and processes incoming data
 * based on a predefined structure. It handles errors, simulates processing delays, and returns 
 * either success or error responses.
 *
 * @param {Object} dataStructure - The expected structure of the incoming data. This defines the shape,
 *                                 types, and required fields of the data.
 * @param {Object|null} data - The actual data being passed to the endpoint. If null, the function 
 *                             will randomly return a success or error response.
 * @param {Array} errorArray - An array of error objects. Each object should have a `message` (with 
 *                             an `error` field) and a `status` code. These errors are used for 
 *                             validation and special error triggers.
 * @param {Object} successOutput - The object that should be returned in case of a successful operation.
 *
 * @returns {Object} - Returns a success object if all validations pass, or an error object if 
 *                     validation fails or special error conditions are triggered.
 *
 * Example Usage:
 * 
 * const orderDetails = {
 *   orderId: { type: "string", required: true },
 *   orderDate: { type: "date", required: true },
 *   items: {
 *     type: "array",
 *     required: true,
 *     structure: {
 *       type: "object",
 *       structure: {
 *         productId: { type: "string", required: true },
 *         quantity: { type: "number", required: true },
 *         price: { type: "number", required: true },
 *       },
 *     },
 *   },
 *   totalPrice: { type: "number", required: true },
 * };
 *
 * const errorArray = [
 *   { error: { key: "Invalid data format" }, status: 400 },
 *   { error: { key: "Required field missing" }, status: 422 },
 *   { error: { key: "Data validation error" }, status: 400 },
 *   { error: { key: "Unexpected error occurred" }, status: 500 },
 * ];
 *
 * const successOutput = {
 *   message: "Order processed successfully",
 *   data: { orderId: "12345", confirmationNumber: "CONF1234567" },
 * };
 *
 * const result = await masterFakeEndpoint(orderDetails, someOrderData, errorArray, successOutput);
 * console.log(result); // Outputs either a success object or an error object
 */

export default async function masterFakeEndpoint(dataStructure, data, errorArray, successOutput) {
  // Simulate a delay to mimic real API processing time
  await simulateApiDelay();

  // Handle cases where data is null or undefined by returning a random error or success response
  if (data === null || data === undefined) {
    return handleNullData(errorArray, successOutput);
  }

  // Check for special error-triggering inputs within the data
  const errorTriggered = checkForErrors(data, errorArray);
  if (errorTriggered) return errorTriggered;

  // Validate the data against the expected structure
  const validationError = validateData(dataStructure, data);
  if (validationError) return validationError;

  // Return the success output if all checks pass
  return successOutput;
}
