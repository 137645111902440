import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const Loader = ({ text = 'Loading...' }) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bgcolor: 'rgba(255, 255, 255, 0.85)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      }}
    >
      <CircularProgress size={60} sx={{ color: '#643CBB' }} />
      <Typography
        sx={{
          mt: 2,
          color: '#643CBB',
          fontSize: '16px',
          fontWeight: '500',
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Loader;
