import React from 'react';
import { Typography, Paper } from '@mui/material';
import SettingBox from '../../ui/organisms/settingBox'; 
import LogoutPanelContainer from './../molecules/logoutPanelContainer';
import AccountStatusPanelContainer from './../molecules/accountStatusPanelContainer';
import ContactSupportPanelContainer from './../molecules/contactSupportPanelContainer'; 
import Settings from "../../functional/organisms/settings";

const SettingsComponent = () => {
    const { tabValue, handleChange } = Settings();

  return (
    <Paper sx={{
      maxWidth: 800,
      margin: 'auto',
      mt: 4,
      p: 2,
      bgcolor: "#F0F0F0",
    }}
    elevation={3}>
      <Typography variant="h6" sx={{ mb: 2, textAlign: 'center' }}>
        Settings
      </Typography>

     <SettingBox
        tabValue={tabValue}
        handleChange={handleChange}
     />

      {tabValue === '1' && <ContactSupportPanelContainer />}
      {tabValue === '3' && <LogoutPanelContainer />}
      {tabValue === '2' && <AccountStatusPanelContainer/>
}

      {/* Future panels can be added here following the same pattern */}
    </Paper>
  );
};

export default SettingsComponent;
