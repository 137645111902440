import React from "react";
import { useSelector } from "react-redux";
import CustomTable from "../../components/molecules/tables/transactions";
import { selectTransactions } from "../../features/transactions/transactionsSelectors";

const TransactionsTable = () => {
  // Fetch transactions and other relevant state
  const transactions = useSelector(selectTransactions);

  // Show only the first 5 transactions
  const displayedTransactions = transactions.slice(0, 4);

  return (
    <CustomTable
      title="Most Recent Transactions"
      columns={[
        { title: "Customer", key: "name" },
        { title: "Invoice ID", key: "id" },
        { title: "Date", key: "date" },
        { title: "Amount", key: "amount" },
        { title: "Cashback Earned", key: "cashbackEarned" },
        { title: "Cashback Redeemed", key: "cashbackAmount" },
        { title: "Status", key: "processing_status" },
        { title: "Register", key: "register" },
      ]}
      data={displayedTransactions}
    />
  );
};

export default TransactionsTable;
