import React from 'react';
import { Dialog, DialogContent, Typography, DialogActions, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const SuccessDialog = ({ open, message, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="success-dialog-title">
      <DialogContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '24px' }}>
        <CheckCircleOutlineIcon style={{ color: 'green', fontSize: 60 }} />
        <Typography style={{ marginTop: '20px', marginBottom: '20px', textAlign: 'center' }}>{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SuccessDialog;
