const statusDetails = {
    "Activation": {
      active: {
        title: "Account Activation Status",
        info: "Congratulations! Your account is now fully active. You've completed all necessary setups, including register setup, cashback configuration, and bank account connection. Your store is now fully equipped to accept payments through our Register App, offer enticing cashback rewards to your customers, and ensure smooth transaction processing directly to your bank account. Welcome aboard!",
      },
      inactive: {
        title: "Account Activation Status",
        info: "Your account is currently inactive, restricting access to key features. Activation requires completing the following essential steps: 1) Setting up your register to enable your employees to accept payments in-store through our Register App. 2) Configuring your cashback settings to decide what rewards your customers will earn when shopping at your store. This is crucial for attracting and retaining customers. 3) Connecting your bank account to facilitate the direct processing of payments. Completing these steps will unlock full account functionality, including making your store discoverable to attract more customers.",
      },
    },
    "Profile Uploaded": {
      active: {
        title: "Profile Upload Status",
        info: "Your profile is successfully uploaded, making your store visible to other users on our platform. This is a vital step towards enhancing your store’s visibility and attracting more customers. Ensure your profile remains up-to-date to best represent your store’s offerings and promotions.",
      },
      inactive: {
        title: "Profile Upload Status",
        info: "You have yet to upload your store profile. Uploading and maintaining an up-to-date profile is essential for visibility on our platform, allowing customers to discover and learn more about what your store offers. Navigate to your profile settings to upload your profile.",
      },
    },
    "Profile Approved": {
      active: {
        title: "Profile Approval Status",
        info: "Your profile has been approved, marking a significant milestone in setting up your presence on our platform. With an approved profile, your store gains credibility and trust, further attracting potential customers. Keep your profile updated to continue engaging effectively with your audience.",
      },
      inactive: {
        title: "Profile Approval Status",
        info: "Your profile awaits approval. Profile approval is a crucial step for ensuring the information presented to potential customers meets our platform’s standards for quality and reliability. Review your profile to ensure it is complete and accurate, enhancing your chances for a swift approval.",
      },
    },
    "Visibility": {
      active: {
        title: "Discoverability",
        info: "Your store is now visible in the Discover app, significantly increasing your potential to attract customers. Visibility in Discover is contingent upon having a fully set up and approved profile, including the addition and verification of your store's locations. This visibility is your gateway to showcasing your cashback offers and driving consumer traffic directly to your store.",
      },
      inactive: {
        title: "Discoverability",
        info: "Your store is currently not visible in the Discover app. To enhance your discoverability, ensure your profile is fully set up and approved. Additionally, adding and verifying your store's locations within our system is crucial. These steps will make your store visible to consumers, allowing you to leverage the Discover app to highlight your cashback offers and attract more customers.",
      },
    },
    "Register Setup": {
      active: {
        title: "Register Setup Complete",
        info: "Your register is fully set up and operational. This setup is foundational for your in-store transactions, enabling your employees to use our Register App for payment processing. A functional register setup is instrumental for a smooth checkout experience, ensuring customer satisfaction and operational efficiency.",
      },
      inactive: {
        title: "Register Setup Incomplete",
        info: "Your register setup remains incomplete, which is crucial for processing in-store transactions through our Register App. Completing this setup enables your staff to manage sales efficiently and provides your customers with a seamless checkout experience. Please proceed with the setup process by following the provided instructions.",
      },
    },
    "Cashback Set": {
      active: {
        title: "Cashback Set",
        info: "You've successfully configured your cashback settings. This feature is essential for incentivizing customers, offering them rewards for their purchases at your store. Effectively communicating your cashback offers can significantly enhance customer loyalty and drive increased sales.",
      },
      inactive: {
        title: "Cashback Not Set",
        info: "Your cashback settings are not configured. Setting up cashback offers is a powerful tool to attract and retain customers by rewarding them for their loyalty. Navigate to your cashback settings to define and activate your offers, tailoring them to your business objectives and customer preferences.",
      },
    },
    "Bank Connected": {
      active: {
        title: "Bank Connected",
        info: "Your bank account is successfully connected, ensuring a seamless flow of funds from transactions processed through our platform. This connection is vital for the efficient management of your finances, allowing for direct deposit of sales revenue into your bank account.",
      },
      inactive: {
        title: "Bank Not Connected",
        info: "Your bank account connection is pending. Connecting your bank account is essential for the direct processing of payments and access to your funds. Please complete the bank connection process to facilitate seamless transactions and financial management.",
      },
    },
    // Add more status details as needed
  };
  
  export default statusDetails;
  