import masterFakeEndpoint from "../../structure";

// Simulate the user-verification-status API
export async function fakeUserVerificationStatus() {
  // Define potential success scenarios
  const successScenarios = [
    // {
    //   status: "certified",
    // },
    {
      status: "retry",
      retry_controller_data: {
        "First Name": { first_name: "John" },
        "Last Name": { last_name: "Doe" },
        "Title": { title: "CEO" },
        "Date Of Birth": { date_of_birth: "1980-01-01" },
        "Ssn": { ssn: "1111" },
        "address": {address:{
          address: "456 Oak St",
          city: "Othertown",
          state: "NY",
          zip_code: "67890",
        }},
      },
      ...(Math.random() > 0.5 && {
        retry_business_data: {
          "Business Name": { business_name: "Doe's Store" },
          "Business Classification": { business_classification: "Retail" },
          "Business Classification Id": { business_classification_id: "12345" },
          "Business Structure": { business_structure: "Sole Proprietorship" },
          "EIN": { ein: "12-3456789" },
          "Doing Business As": { doing_business_as: "Doe's Corner Store" },
          "Website": { website: "http://www.doessstore.com" },
          "address": {address:{
            address: "456 Oak St",
            city: "Othertown",
            state: "NY",
            zip_code: "67890",
          }},
        },
      }),
      ...(Math.random() > 0.5 && {
        owner_retry_data: [
          {
            "id": 1,
            "First Name": { first_name: "Jane" },
            "Last Name": { last_name: "Doe" },
            "Date Of Birth": { date_of_birth: "1985-02-02" },
            "SSN": { ssn: "222-22-2222" },
            "address": {address:{
              address: "456 Oak St",
              city: "Othertown",
              state: "NY",
              zip_code: "67890",
            }},
          },
          ...(Math.random() > 0.5 ? [{
            "id": 2,
            "First Name": { first_name: "John" },
            "Last Name": { last_name: "Smith" },
            "Date Of Birth": { date_of_birth: "1980-03-03" },
            "SSN": { ssn: "333-33-3333" },
            "address": {address:{
              address: "789 Pine St",
              city: "Anytown",
              state: "CA",
              zip_code: "12345",
            }},
          }] : []),
          ...(Math.random() > 0.5 ? [{
            "id": 3,
            "First Name": { first_name: "Emily" },
            "Last Name": { last_name: "White" },
            "Date Of Birth": { date_of_birth: "1990-04-04" },
            "SSN": { ssn: "444-44-4444" },
            "address": {address:{
              address: "101 Maple St",
              city: "Sometown",
              state: "TX",
              zip_code: "23456",
            }},
          }] : []),
        ].filter(Boolean),
      }),
    },
    // {
    //   status: "document",
    //   documents: [
    //     {
    //       serverName: "Controller",
    //       name: "Controller ID",
    //       types: ["license", "idCard", "passport"],
    //       helpText: "For US persons: Upload a non-expired state-issued driver's license or ID card. For non-US persons: Upload a non-expired passport.",
    //       headerText: "Upload a clear, valid identification document for the controller.",
    //       ...(Math.random() > 0.5 && { errors: ["Document not clear", "Incorrect format"] }),
    //     },
    //     ...(Math.random() > 0.5 ? [{
    //       serverName: "Business",
    //       name: "Business Document",
    //       types: ["other"],
    //       helpText: "Upload an EIN letter or other government-issued document that includes your business name and address.",
    //       headerText: "Ensure the document clearly identifies your business.",
    //       ...(Math.random() > 0.5 && { errors: ["Document too old", "Incorrect format"] }),
    //     }] : []),
    //     ...(Math.random() > 0.5 ? [{
    //       serverName: "DBA",
    //       name: "DBA Document",
    //       types: ["other"],
    //       helpText: "Upload a DBA document issued by the government, including the DBA and registered business names.",
    //       headerText: "Provide a clear, valid DBA document.",
    //       ...(Math.random() > 0.5 && { errors: ["Document missing", "Invalid format"] }),
    //     }] : []),
    //     ...(Math.random() > 0.5 ? [{
    //       id: 1,
    //       serverName: "Owner",
    //       name: "Owner Jane Doe's ID",
    //       types: ["license", "idCard", "passport"],
    //       helpText: "Upload a clear, non-expired government-issued ID for the owner. Acceptable IDs include a driver’s license, state ID card, or passport.",
    //       headerText: "Documentation needed for Jane Doe. Ensure the document is valid and clearly visible.",
    //       ...(Math.random() > 0.5 && { errors: ["Document not clear", "Expired document"] }),
    //     }] : []),
    //   ],
    // },
    // {
    //   status: "suspended",
    // },
    // {
    //   status: "documentPending",
    // },
  ];

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Randomly select one of the success scenarios
  const randomSuccess = successScenarios[Math.floor(Math.random() * successScenarios.length)];

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(null, null, errorArray, randomSuccess);
}
