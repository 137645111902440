import masterFakeEndpoint from "../../structure";

// Utility function to determine if data should be included (90% chance)
function shouldIncludeData() {
  return Math.random() < 0.9;
}

// Simulate the Tabs Data API
export async function fakeTabsDataApi(data) {
  // Define the expected data structure for this endpoint (no input required, but can validate token if needed)
  const dataStructure = {}; // No required input fields, but you could add token verification logic here

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output with conditional data inclusion
  const successOutput = {
    transactions: shouldIncludeData() ? "10,000.00" : "0",
    cashbackEarned: shouldIncludeData() ? "500.00" : "0",
    cashbackRedeemed: shouldIncludeData() ? "300.00" : "0",
    totalUsers: shouldIncludeData() ? "100" : "0",
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(dataStructure, data, errorArray, successOutput);
}
