import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const DocumentCheckRouteGuard = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { documents } = location.state || {};
  const userStatus = useSelector((state) => state.user.userStatus);

  useEffect(() => {
    if (!documents) {
      // Perform user status-based redirection if documents are missing
      switch (userStatus) {
        case 'notAuthenticated':
          navigate('/sign-in', { replace: true });
          break;
        case 'personalInfo':
          navigate('/personal-info', { replace: true });
          break;
        case 'businessInfo':
          navigate('/business-info', { replace: true });
          break;
        case 'verify':
          navigate('/status', { replace: true });
          break;
        case 'verifyPending':
          navigate('/status/pending', { replace: true });
          break;
        case 'verifySuspended':
          navigate('/status/suspended', { replace: true });
          break;
        default:
          console.log("unknown status - " + userStatus);
          navigate('/sign-in', { replace: true });
          break;
      }
    }
  }, [documents, navigate, userStatus]);

  return documents ? <>{children}</> : null;
};

export default DocumentCheckRouteGuard;
