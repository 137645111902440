import React from "react";
import {
  IconButton,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const RequirementIcon = ({ completed, name, handleIconClick }) => (
    <IconButton onClick={() => handleIconClick(name, completed)}>
      {completed ? (
        <CheckCircleOutlineIcon color="success" />
      ) : (
        <HighlightOffIcon color="error" />
      )}
    </IconButton>
  );


  export default RequirementIcon;