import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuIcon from "@mui/icons-material/Menu";
import Box from "@mui/material/Box";

const TopBarComponent = ({ toggleDrawer, currentUser, isMdUp }) => {
  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={1}
      sx={{
        borderRadius: 0,
        margin: 0,
        width: "100%",
        height: 75,
        alignContent: "center",
        justifyContent: "center",
      }}
    >
      <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
        {/* Conditionally render the MenuIcon based on screen size */}
        {!isMdUp && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h6"
          color="inherit"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          Dashboard
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1" sx={{ marginRight: "10px" }}>
            Hi, {currentUser}
          </Typography>
          <IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBarComponent;
