import masterFakeEndpoint from "../structure";

// Simulate the confirm-email-otp API
export async function fakeConfirmEmailOTP(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    otp: { type: "string", required: true },
    profile_pk: { type: "number", required: true },
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "Email not found." }, status: 400 },
    { error: { otp: "Invalid code entered." }, status: 400 },
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    profile_pk: data.profile_pk, // Return the profile_pk provided in the request
    email: "confirmed@example.com", // Simulate the confirmed email address
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
