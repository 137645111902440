import React from "react";

import MainLayout from "../../../../components/pageStructures/dashboard/main";
import CashbackContainer from "../organisms/cashbackContainer";
const CashbackPage = () => {
  return (
    <MainLayout>
      <CashbackContainer />
    </MainLayout>
  );
};

export default CashbackPage;
