import React from "react";
import useConfirmCodeDialog from "../../functionality/molecules/useConfirmCodeDialog";
import ConfirmCodeDialogUI from "../../ui/molecules/ConfirmCodeDialogUI";

const ConfirmCodeDialog = (props) => {
  const {
    credential,
    otp,
    errors,
    setCredential,
    setOtp,
    handleSubmit,
  } = useConfirmCodeDialog(props);

  return (
    <ConfirmCodeDialogUI
      {...props}
      credential={credential}
      otp={otp}
      errors={errors}
      setCredential={setCredential}
      setOtp={setOtp}
      handleSubmit={handleSubmit}
    />
  );
};

export default ConfirmCodeDialog;
