import { useState } from "react";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";


const useRequestCodeDialog = (onSuccess) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = () => {
    const errorValidation = [{ field: 'email', type: 'email', required: true }];

    fetchFeedback({
      endpoint: "merchant/reset/email/",
      requestType: "POST",
      loaderType: 'dialogueLoader',
      auth: false, 
      data: { email },
      setError: setError,
      validationRules: errorValidation,
      loaderMessage: "Verifying Email...",
      onSuccess: (response) => onSuccess(response.profile_pk),
    });
  };

  return {
    email,
    error,
    setEmail,
    handleSubmit,
  };
};

export default useRequestCodeDialog;
