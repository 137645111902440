import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { selectBankData } from "../../../features/banking/bankSelectors";



import BankTab from "./bankTab";
import NoBankAccount from "./noBank";


const BankAccount = () => {
    const bankData = useSelector(selectBankData);
  const hasBankAccount = bankData && bankData.accountNumber;

  return (
    <>

      
            {hasBankAccount ? <BankTab /> : <NoBankAccount />}
          
    </>
  );
};

export default BankAccount;
