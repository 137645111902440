import { Box } from "@mui/system";
import React from "react";

import CashbackModalContainer from "../molecules/cashbackModalContainer";
import CashbackUpdateModalContainer from "../molecules/cashbackUpdateModalContainer";
import CashbackHistoryModalContainer from "../molecules/cashbackHistoryModalContainer";
import useCashback from "../../functional/organisms/useCashback";

const CashbackContainer = () => {
  const {
    currentCashback,
    cashbackHistory,
    isUpdateModalOpen,
    isHistoryModalOpen,
    handleOpenUpdateModal,
    handleCloseUpdateModal,
    handleOpenHistoryModal,
    handleCloseHistoryModal,
  } = useCashback();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        margin: "auto",
        alignItems: "center",
      }}
    >
      <CashbackModalContainer
        onViewHistory={handleOpenHistoryModal}
        onUpdateCashback={handleOpenUpdateModal}
        currentCashback={currentCashback}
      />
      <CashbackUpdateModalContainer
        open={isUpdateModalOpen}
        onClose={handleCloseUpdateModal}
        currentCashback={currentCashback}
      />
      <CashbackHistoryModalContainer
        open={isHistoryModalOpen}
        onClose={handleCloseHistoryModal}
        cashbackHistory={cashbackHistory}
      />
    </Box>
  );
};

export default CashbackContainer;
