import { fakeSendMerchantEmailOTP } from "./fakeEndpoints/endpoints/SendMerchantEmailOTP";
import { fakeConfirmEmailOTP } from "./fakeEndpoints/endpoints/ConfirmEmailOTP";
import { fakeCreateMerchantAccount } from "./fakeEndpoints/endpoints/createAccount";
import { fakeCreatePersonalInfo } from "./fakeEndpoints/endpoints/personal-info";
import { fakeCreateBusinessInfo } from "./fakeEndpoints/endpoints/businessInfo";
import { fakeUserVerificationStatus } from "./fakeEndpoints/endpoints/dwolla/verify-status";
import { fakeUploadDocumentsForVerification } from "./fakeEndpoints/endpoints/dwolla/doc-upload";
import { fakeUserRetryVerification } from "./fakeEndpoints/endpoints/merchant-user/retry";
import { fakeMerchantSignIn } from "./fakeEndpoints/endpoints/merchant-user/sign-in";
import { fakeConfirmEmailAndCredential } from "./fakeEndpoints/endpoints/merchant-user/confirm-email-credential"; // Import the new function
import { fakeResetCredential } from "./fakeEndpoints/endpoints/merchant-user/reset-credential"; // Import the new function
import { fakeSendMerchantResetEmailOTP } from "./fakeEndpoints/endpoints/communication/reset-email"; // Import the new function
import { fakeMerchantDashboardData } from "./fakeEndpoints/endpoints/merchant-data-provider/main";
import { fakeTabsDataApi } from "./fakeEndpoints/endpoints/merchant-data-provider/tabs";
import { fakeRegisterAccount } from "./fakeEndpoints/endpoints/register/create-account";
import { fakeUpdateCashback } from "./fakeEndpoints/endpoints/cashback/update";
import { fakeTransactionsList } from "./fakeEndpoints/endpoints/transactions/list";
import { fakeCreateMerchantLocation } from "./fakeEndpoints/endpoints/locations/add";
import { fakeDeleteMerchantLocation } from "./fakeEndpoints/endpoints/locations/delete";
const BASE_URL = "api/";

export async function masterComponentFakeFetch(endpoint, requestType, data = null) {
  endpoint = BASE_URL + endpoint;

  const normalizedEndpoint = endpoint.replace(/\/\d+\//, '/1/');


  switch (normalizedEndpoint.split('?')[0]) {
    case "api/merchant/email/":
      if (requestType === "POST") {
        return fakeSendMerchantEmailOTP(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/sign-in/":
      if (requestType === "POST") {
        return fakeMerchantSignIn(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }


   
    case "api/confirm-email-otp/":
      if (requestType === "POST") {
        return fakeConfirmEmailOTP(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/account_info/":
      if (requestType === "POST") {
        return fakeCreateMerchantAccount(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/personal_info/":
      if (requestType === "POST") {
        return fakeCreatePersonalInfo(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/business_info/":
      if (requestType === "POST") {
        return fakeCreateBusinessInfo(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/verification-status/":
      if (requestType === "GET") {
        return fakeUserVerificationStatus(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/document/upload/":
      if (requestType === "POST") {
        return fakeUploadDocumentsForVerification(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/retry-info/":
      if (requestType === "POST") {
        return fakeUserRetryVerification(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/reset-check/": // Handle the new confirm email and credential endpoint
      if (requestType === "POST") {
        return fakeConfirmEmailAndCredential(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

    case "api/merchant/reset-credential/": // Handle the new reset credential endpoint
      if (requestType === "POST") {
        return fakeResetCredential(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }
      case "api/merchant/reset/email/": // Handle the new reset credential endpoint
      if (requestType === "POST") {
        return fakeSendMerchantResetEmailOTP(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }
      case "api/merchant/dashboard/data/": // Handle the new reset credential endpoint
      if (requestType === "GET") {
        return fakeMerchantDashboardData(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

      case "api/merchant/tabs/data/": 
      if (requestType === "GET") {
        return fakeTabsDataApi(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

      case "api/merchant/register-account/":  // New endpoint for creating merchant account
      if (requestType === "POST") {
        return fakeRegisterAccount(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

      case "api/merchant/update-cashback/":  // New endpoint for creating merchant account
      if (requestType === "POST") {
        return fakeUpdateCashback(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

      case "api/merchant/transactions/":  // New endpoint for creating merchant account
      if (requestType === "GET") {
        return fakeTransactionsList(data);
      } else {
        return {
          error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
        };
      }

      case "api/merchant/locations/":
   
        if (requestType === "POST") {
          return fakeCreateMerchantLocation(data);
        } else {
          return {
            error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
          };
        }
  
      case "api/merchant/locations/1/":
        if (requestType === "DELETE") {
          // Extract the actual ID from the original endpoint
          const idMatch = endpoint.match(/\/(\d+)\//);
          const id = idMatch ? parseInt(idMatch[1], 10) : null;
          if (id) {
            return fakeDeleteMerchantLocation({ id, ...data });
          } else {
            return {
              error: `Invalid ID provided in the endpoint: ${endpoint}`,
            };
          }
        } else {
          return {
            error: `Unsupported request type: ${requestType} for endpoint: ${endpoint}`,
          };
        }
  



   


    default:
      return { error: `Endpoint not found: ${endpoint}` };
  }
}
