// AccountInfoPage.js
import React from "react";
import UserOptions from "../molecules/UserOptions";
import AccountInfoPageUI from "../../ui/page/AccountInfoPageUI";
import useAccountInfo from "../../functionality/page/main";

const SignInPage = () => {
  const { accountInfo, setAccountInfo, error, handleSubmit } = useAccountInfo();

  return (
    <AccountInfoPageUI
      accountInfo={accountInfo}
      setAccountInfo={setAccountInfo}
      error={error}
      handleSubmit={handleSubmit}
    >
      <UserOptions /> {/* Passing UserOptions as children */}
    </AccountInfoPageUI>
  );
};

export default SignInPage;
