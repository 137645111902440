import CashbackModalUI from "../../ui/molecules/cashbackModalUI";

const CashbackModalContainer = ({ onViewHistory, onUpdateCashback }) => {
  return (
    <CashbackModalUI
      onViewHistory={onViewHistory}
      onUpdateCashback={onUpdateCashback}
    />
  );
};

export default CashbackModalContainer;
