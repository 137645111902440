import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import UpdateConfirmationDialog from "./updatePopUp";
import InfoIconButton from "../../../components/molecules/dialogues/info";
import GeneralButton from "../../../components/atoms/buttons/GeneralButton";
import { useSelector } from "react-redux";
import { selectRegisterData } from "../../../features/register/registerSelectors";

const RegisterSettingsDisplay = ({ onClick }) => {
  const registerData = useSelector(selectRegisterData);
  const [showUpdateDialog, setShowUpdateDialog] = useState(false);

  const registerSettings = [
    { primary: "Username", secondary: registerData.username },
    { primary: "Password", secondary: "******" },
    { primary: "Refund PIN", secondary: registerData.register_pin },
    { primary: "Registers in Use", secondary: registerData.register_count },
  ];

  const handleSubmit = (username, password) => {
    setShowUpdateDialog(false);
    onClick(username, password);
  };

  return (
    <>
      <Card
        sx={{
          minWidth: 380,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 2,
          backgroundColor: "white",
          boxShadow: "1px 1px 20px rgba(0, 0, 0, 0.1)",
          mb: 2,
          position: "relative",
        }}
      >
        <CardContent>
          <Typography
            variant="h6"
            sx={{ mb: 2, fontWeight: "bold", textAlign: "center" }}
          >
            Register Settings
          </Typography>
          <List>
            {registerSettings.map((item, index) => (
              <ListItem
                key={index}
                divider={index < registerSettings.length - 1}
              >
                <ListItemText
                  primary={item.primary}
                  secondary={item.secondary}
                  sx={{ textAlign: "center" }}
                />
              </ListItem>
            ))}
          </List>
          <GeneralButton
            label="Update Register Login"
            onClick={() => setShowUpdateDialog(true)}
            type="outlined"
          />
        </CardContent>
        <InfoIconButton
          title={"Register App Help"}
          info={
            "Once you set up a username and password, you and your employees will be able to log into the register in-store. This allows for efficient and secure payment processing with Clavaa, enhancing your customer service experience."
          }
        />
      </Card>

      {showUpdateDialog && (
        <UpdateConfirmationDialog
          open={showUpdateDialog}
          onClose={() => setShowUpdateDialog(false)}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default RegisterSettingsDisplay;
