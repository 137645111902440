import React from "react";
import TopBarComponent from "../ui/TopBarUi";
import { useTopBar } from "../functional/useTopBar";

const TopBarContainer = ({ toggleDrawer }) => {
  const { isMdUp, currentUser } = useTopBar();

  return (
    <TopBarComponent
      toggleDrawer={toggleDrawer}
      currentUser={currentUser}
      isMdUp={isMdUp}
    />
  );
};

export default TopBarContainer;
