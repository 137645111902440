const businessClassifications = [
    { label: "Gourmet foods", value: "9ed3f671-7d6f-11e3-803c-5404a6144203" },
    { label: "Distilleries", value: "9ed3f66c-7d6f-11e3-86ae-5404a6144203" },
    { label: "Breweries", value: "9ed3f66a-7d6f-11e3-8acd-5404a6144203" },
    { label: "Alcoholic beverage drinking places", value: "9ed3f66d-7d6f-11e3-9101-5404a6144203" },
    { label: "Beer, wine, and liquor store", value: "9ed3f66e-7d6f-11e3-9480-5404a6144203" },
    { label: "Wineries", value: "9ed3f66b-7d6f-11e3-95ac-5404a6144203" },
    { label: "Tobacco", value: "9ed3f674-7d6f-11e3-9619-5404a6144203" },
    { label: "Restaurant", value: "9ed3f673-7d6f-11e3-adb1-5404a6144203" },
    { label: "Supplement store", value: "9ed3f676-7d6f-11e3-af8e-5404a6144203" },
    { label: "Pharmacy and drugstore", value: "9ed3f675-7d6f-11e3-afad-5404a6144203" },
    { label: "Coffee and tea", value: "9ed3f670-7d6f-11e3-b1ce-5404a6144203" },
    { label: "Catering services", value: "9ed3f66f-7d6f-11e3-b1df-5404a6144203" },
    { label: "Specialty and miscellaneous food store", value: "9ed3f672-7d6f-11e3-b67a-5404a6144203" },
    { label: "Nonmetallic mineral product manufacturing", value: "9ed41d96-7d6f-11e3-804a-5404a6144203" },
    { label: "Furniture and related product manufacturing", value: "9ed44484-7d6f-11e3-8b25-5404a6144203" },
    { label: "Plastics and rubber products manufacturing", value: "9ed41d95-7d6f-11e3-8b36-5404a6144203" },
    { label: "Chemical manufacturing", value: "9ed41d94-7d6f-11e3-8dc3-5404a6144203" },
    { label: "Primary metal manufacturing", value: "9ed41d97-7d6f-11e3-92aa-5404a6144203" },
    { label: "Transportation equipment manufacturing", value: "9ed44483-7d6f-11e3-95ce-5404a6144203" },
    { label: "Leather and allied product manufacturing", value: "9ed41d8f-7d6f-11e3-9762-5404a6144203" },
    { label: "Textile product mills", value: "9ed41d8d-7d6f-11e3-9a24-5404a6144203" },
    { label: "Printing and related support activities", value: "9ed41d92-7d6f-11e3-a0ad-5404a6144203" },
    { label: "Miscellaneous manufacturing", value: "9ed44485-7d6f-11e3-a2bc-5404a6144203" },
    { label: "Food manufacturing", value: "9ed41d8a-7d6f-11e3-a5dc-5404a6144203" },
    { label: "Fabricated metal product manufacturing", value: "9ed41d98-7d6f-11e3-a88a-5404a6144203" },
    { label: "Wood product manufacturing", value: "9ed41d90-7d6f-11e3-a8f0-5404a6144203" },
    { label: "Computer and electronic product manufacturing", value: "9ed44481-7d6f-11e3-aa45-5404a6144203" },
    { label: "Paper manufacturing", value: "9ed41d91-7d6f-11e3-b262-5404a6144203" },
    { label: "Machinery manufacturing", value: "9ed44480-7d6f-11e3-b289-5404a6144203" },
    { label: "Apparel manufacturing", value: "9ed41d8e-7d6f-11e3-b45c-5404a6144203" },
    { label: "Petroleum and coal products manufacturing", value: "9ed41d93-7d6f-11e3-b677-5404a6144203" },
    { label: "Beverage and tobacco product manufacturing", value: "9ed41d8b-7d6f-11e3-b721-5404a6144203" },
    { label: "Electrical equipment, appliance, and component manufacturing", value: "9ed44482-7d6f-11e3-babe-5404a6144203" },
    { label: "Textile mills", value: "9ed41d8c-7d6f-11e3-bce3-5404a6144203" },
     // ... previous entries
     { label: "Agriculture - animal production and aquaculture", value: "9ed38132-7d6f-11e3-815d-5404a6144203" },
     { label: "Agriculture - fruit, vegetable, and crop production", value: "9ed38131-7d6f-11e3-828e-5404a6144203" },
     { label: "Consulting services", value: "9ed3813c-7d6f-11e3-82cc-5404a6144203" },
     { label: "Agriculture - fishing, hunting, and trapping", value: "9ed38134-7d6f-11e3-8529-5404a6144203" },
     { label: "Construction - residential building", value: "9ed38138-7d6f-11e3-862a-5404a6144203" },
     { label: "Consumer goods rental", value: "9ed3813e-7d6f-11e3-86eb-5404a6144203" },
     { label: "Commercial and industrial goods rental", value: "9ed3813f-7d6f-11e3-8840-5404a6144203" },
     { label: "Marketing", value: "9ed38146-7d6f-11e3-89d6-5404a6144203" },
     { label: "Publishing", value: "9ed3814a-7d6f-11e3-8c10-5404a6144203" },
     { label: "Shipping and packing", value: "9ed3814d-7d6f-11e3-8eb5-5404a6144203" },
     { label: "Stenographic and secretarial support services", value: "9ed3814e-7d6f-11e3-9087-5404a6144203" },
     { label: "Multi-level marketing", value: "9ed38147-7d6f-11e3-94f8-5404a6144203" },
     { label: "Office and commercial furniture", value: "9ed38148-7d6f-11e3-9820-5404a6144203" },
     { label: "Agriculture - forestry and logging", value: "9ed38133-7d6f-11e3-9892-5404a6144203" },
     { label: "Printing", value: "9ed3814b-7d6f-11e3-9ed9-5404a6144203" },
     { label: "Mailing lists", value: "9ed38145-7d6f-11e3-a442-5404a6144203" },
     { label: "Industrial and manufacturing supplies", value: "9ed38144-7d6f-11e3-a4f3-5404a6144203" },
     { label: "Commercial and industrial goods repair and maintenance", value: "9ed38141-7d6f-11e3-a5d2-5404a6144203" },
     { label: "Quick copy and reproduction services", value: "9ed3814c-7d6f-11e3-a77b-5404a6144203" },
     { label: "Commercial photography, art, and graphics", value: "9ed38137-7d6f-11e3-a92b-5404a6144203" },
     { label: "Architectural, engineering, and surveying services", value: "9ed38135-7d6f-11e3-a9f8-5404a6144203" },
     { label: "Wholesale - durable goods", value: "9ed3814f-7d6f-11e3-aaf6-5404a6144203" },
     { label: "Consumer goods repair and maintenance", value: "9ed38142-7d6f-11e3-ab4a-5404a6144203" },
     { label: "Wholesale - nondurable goods", value: "9ed38150-7d6f-11e3-adfb-5404a6144203" },
     { label: "Advertising and public relations", value: "9ed38130-7d6f-11e3-afd9-5404a6144203" },
     { label: "Construction - specialty trade contractors", value: "9ed3813b-7d6f-11e3-b28d-5404a6144203" },

       { label: "Real estate - other", value: "9ed492ab-7d6f-11e3-9907-5404a6144203" },
       { label: "Storage", value: "9ed46bae-7d6f-11e3-9966-5404a6144203" },
       { label: "Medical care", value: "9ed46ba9-7d6f-11e3-9b47-5404a6144203" },
       { label: "Services not elsewhere classified", value: "9ed492a1-7d6f-11e3-9ccf-5404a6144203" },
       { label: "Importing and exporting", value: "9ed46ba1-7d6f-11e3-9d1b-5404a6144203" },
       { label: "Child care services", value: "9ed444a4-7d6f-11e3-9dc4-5404a6144203" },
       { label: "Insurance - auto and home", value: "9ed46ba3-7d6f-11e3-9efe-5404a6144203" },
       { label: "Counseling services", value: "9ed46b92-7d6f-11e3-9f08-5404a6144203" },
       { label: "Graphic and commercial design", value: "9ed46b9e-7d6f-11e3-9fec-5404a6144203" },
       { label: "Moving", value: "9ed46bad-7d6f-11e3-a006-5404a6144203" },
       { label: "Event and wedding planning", value: "9ed46b9a-7d6f-11e3-a11b-5404a6144203" },
       { label: "Electronic repair and maintenance", value: "9ed46b96-7d6f-11e3-a2ee-5404a6144203" },
       { label: "Quick copy and reproduction services", value: "9ed46bb3-7d6f-11e3-a347-5404a6144203" },
       { label: "Photofinishing", value: "9ed46bb0-7d6f-11e3-a35d-5404a6144203" },
       { label: "Radio, television, and stereo repair", value: "9ed46bb4-7d6f-11e3-a3c3-5404a6144203" },
       { label: "Publishing", value: "9ed46bab-7d6f-11e3-a401-5404a6144203" },
       { label: "Lottery and contests", value: "9ed46ba8-7d6f-11e3-a726-5404a6144203" },
       { label: "Research and development", value: "9ed492aa-7d6f-11e3-aca1-5404a6144203" },
       { label: "Photographic studios - portraits", value: "9ed46bb1-7d6f-11e3-acac-5404a6144203" },
       { label: "Commercial photography, art, and graphics", value: "9ed444a6-7d6f-11e3-ad1c-5404a6144203" },
       { label: "Construction - residential building", value: "9ed46b9c-7d6f-11e3-ad21-5404a6144203" },
       { label: "Membership clubs and organizations", value: "9ed46baa-7d6f-11e3-ad74-5404a6144203" },
       { label: "Dental care", value: "9ed46b94-7d6f-11e3-ad88-5404a6144203" },
       { label: "Legal services and attorneys", value: "9ed46ba6-7d6f-11e3-ae95-5404a6144203" },
       { label: "Mining", value: "9ed492a9-7d6f-11e3-af4b-5404a6144203" },
        { label: "Landscaping and horticultural", value: "9ed46ba5-7d6f-11e3-b01c-5404a6144203" },
        { label: "Information retrieval services", value: "9ed46ba2-7d6f-11e3-b242-5404a6144203" },
        { label: "Insurance - life and annuity", value: "9ed46ba4-7d6f-11e3-b31e-5404a6144203" },
        { label: "Gambling", value: "9ed46b9b-7d6f-11e3-b391-5404a6144203" },
        { label: "Courier services", value: "9ed46b93-7d6f-11e3-b3b9-5404a6144203" },
        { label: "Tailors and alterations", value: "9ed492a4-7d6f-11e3-b3dc-5404a6144203" },
        { label: "Computer and data processing services", value: "9ed444a7-7d6f-11e3-b42d-5404a6144203" },
        { label: "Construction - nonresidential building", value: "9ed46b9d-7d6f-11e3-b5da-5404a6144203" },
        { label: "IDs, licenses, and passports", value: "9ed46ba0-7d6f-11e3-b775-5404a6144203" },
        { label: "Carpentry", value: "9ed444a3-7d6f-11e3-b785-5404a6144203" },
        { label: "Local delivery service", value: "9ed46ba7-7d6f-11e3-b85c-5404a6144203" },
        { label: "Advertising and public relations", value: "9ed4449f-7d6f-11e3-b867-5404a6144203" },
        { label: "Reupholstery and furniture repair", value: "9ed46bb7-7d6f-11e3-b950-5404a6144203" },
        { label: "Rental property management", value: "9ed46bb6-7d6f-11e3-b9b2-5404a6144203" },
        { label: "Career services", value: "9ed444a2-7d6f-11e3-ba23-5404a6144203" },
        { label: "Utilities", value: "9ed492a6-7d6f-11e3-bbd2-5404a6144203" },
        { label: "Commercial and industrial goods rental", value: "9ed46b99-7d6f-11e3-be4c-5404a6144203" },
        { label: "Shipping and packing", value: "9ed492a2-7d6f-11e3-bfb3-5404a6144203" },

            // ... previous entries
            { label: "Toys and games", value: "9ed3cf58-7d6f-11e3-81a4-5404a6144203" },
            { label: "Music", value: "9ed3cf50-7d6f-11e3-8ae8-5404a6144203" },
            { label: "Gambling", value: "9ed3cf5c-7d6f-11e3-8d0e-5404a6144203" },
            { label: "Cable, satellite, and other pay TV and radio broadcasting", value: "9ed3cf53-7d6f-11e3-8ee9-5404a6144203" },
            { label: "Slot machines", value: "9ed3cf59-7d6f-11e3-9158-5404a6144203" },
            { label: "Theater tickets", value: "9ed3cf57-7d6f-11e3-921d-5404a6144203" },
            { label: "Motion picture and video", value: "9ed3cf4f-7d6f-11e3-97ea-5404a6144203" },
            { label: "Digital content", value: "9ed3cf5a-7d6f-11e3-9a99-5404a6144203" },
            { label: "Entertainers", value: "9ed3cf5b-7d6f-11e3-a368-5404a6144203" },
            { label: "Memorabilia", value: "9ed3a867-7d6f-11e3-a6e4-5404a6144203" },
            { label: "Music store - CDs, cassettes and albums", value: "9ed3cf52-7d6f-11e3-b0da-5404a6144203" },
            { label: "Online gaming", value: "9ed3cf5d-7d6f-11e3-b35e-5404a6144203" },
            { label: "Adult digital content", value: "9ed3cf55-7d6f-11e3-b43c-5404a6144203" },
            { label: "Movie store - DVDs, videotapes", value: "9ed3cf51-7d6f-11e3-b49f-5404a6144203" },
            { label: "Video games and systems", value: "9ed3cf5e-7d6f-11e3-b9d5-5404a6144203" },
            { label: "Concert tickets", value: "9ed3cf56-7d6f-11e3-ba87-5404a6144203" },
            { label: "Cable and other subscription programming", value: "9ed3cf54-7d6f-11e3-bf23-5404a6144203" },
                { label: "Antiques", value: "9ed41d76-7d6f-11e3-81ef-5404a6144203" },
                { label: "Glass, paint, and wallpaper", value: "9ed41d80-7d6f-11e3-857a-5404a6144203" },
                { label: "Appliances", value: "9ed41d77-7d6f-11e3-8cda-5404a6144203" },
                { label: "Exterminating and disinfecting services", value: "9ed41d7c-7d6f-11e3-8fb6-5404a6144203" },
                { label: "Drapery, window covering, and upholstery", value: "9ed41d7b-7d6f-11e3-9534-5404a6144203" },
                { label: "Hardware and tools", value: "9ed41d81-7d6f-11e3-9904-5404a6144203" },
                { label: "Rugs and carpets", value: "9ed41d86-7d6f-11e3-9e98-5404a6144203" },
                { label: "Furniture", value: "9ed41d7e-7d6f-11e3-a3fc-5404a6144203" },
                { label: "Construction material", value: "9ed41d7a-7d6f-11e3-ae21-5404a6144203" },
                { label: "Lawn and garden equipment and supplies", value: "9ed41d7f-7d6f-11e3-b918-5404a6144203" },
                { label: "Home furnishings store", value: "9ed41d84-7d6f-11e3-bc20-5404a6144203" },
                { label: "Art dealers and galleries", value: "9ed41d78-7d6f-11e3-bd3f-5404a6144203" },
                    { label: "Safety and health", value: "9ed35a2d-7d6f-11e3-8284-5404a6144203" },
                    { label: "Furniture", value: "9ed35a2b-7d6f-11e3-942f-5404a6144203" },
                    { label: "Children's and baby's clothing", value: "9ed35a2a-7d6f-11e3-b976-5404a6144203" },
                    { label: "Tours", value: "9ed4b9b8-7d6f-11e3-8329-5404a6144203" },
                    { label: "Bus line", value: "9ed4b9b0-7d6f-11e3-8f2f-5404a6144203" },
                    { label: "Airline", value: "9ed492c7-7d6f-11e3-8fc5-5404a6144203" },
                    { label: "Sporting and recreation camps", value: "9ed4b9b5-7d6f-11e3-9031-5404a6144203" },
                    { label: "Cruises", value: "9ed4b9b1-7d6f-11e3-9ce7-5404a6144203" },
                    { label: "Lodging and accommodations", value: "9ed4b9b2-7d6f-11e3-a1d5-5404a6144203" },
                    { label: "Timeshares", value: "9ed4b9b7-7d6f-11e3-a615-5404a6144203" },
                    { label: "Luggage and leather goods", value: "9ed4b9b3-7d6f-11e3-aea7-5404a6144203" },
                    { label: "Travel agency", value: "9ed4b9bb-7d6f-11e3-af9f-5404a6144203" },
                    { label: "Taxicabs and limousines", value: "9ed4b9b6-7d6f-11e3-b843-5404a6144203" },
                    { label: "Auto rental", value: "9ed492c8-7d6f-11e3-ba44-5404a6144203" },
                    { label: "Transportation services - other", value: "9ed4b9ba-7d6f-11e3-bbf4-5404a6144203" },
                    { label: "Trailer parks and campgrounds", value: "9ed4b9b9-7d6f-11e3-bf00-5404a6144203" },
                    { label: "Women's clothing", value: "9ed38155-7d6f-11e3-83c3-5404a6144203" },
                    { label: "Military and civil service uniforms", value: "9ed3a841-7d6f-11e3-954f-5404a6144203" },
                    { label: "Children's and baby's clothing", value: "9ed38153-7d6f-11e3-97e3-5404a6144203" },
                    { label: "Men's clothing", value: "9ed38154-7d6f-11e3-9b0d-5404a6144203" },
                    { label: "Wholesale - precious stones and metals", value: "9ed3a844-7d6f-11e3-9d68-5404a6144203" },
                    { label: "Shoes", value: "9ed3a840-7d6f-11e3-a314-5404a6144203" },
                    { label: "Fashion jewelry", value: "9ed3a845-7d6f-11e3-b307-5404a6144203" },
                    { label: "Accessories", value: "9ed3a842-7d6f-11e3-bba3-5404a6144203" },
                    { label: "Retail - fine jewelry and watches", value: "9ed3a843-7d6f-11e3-bbb4-5404a6144203" },
                        { label: "Dental care", value: "9ed41d70-7d6f-11e3-851b-5404a6144203" },
                        { label: "Vitamins and supplements", value: "9ed41d74-7d6f-11e3-8b34-5404a6144203" },
                        { label: "Vision care", value: "9ed41d73-7d6f-11e3-963f-5404a6144203" },
                        { label: "Pharmacy and drugstore", value: "9ed3f687-7d6f-11e3-a1ac-5404a6144203" },
                        { label: "Medical care", value: "9ed41d71-7d6f-11e3-abed-5404a6144203" },
                        { label: "Medical equipment and supplies", value: "9ed41d72-7d6f-11e3-b770-5404a6144203" },
                        { label: "Bath and body", value: "9ed35a2f-7d6f-11e3-8593-5404a6144203" },
                        { label: "Fragrances and perfumes", value: "9ed35a30-7d6f-11e3-b9d5-5404a6144203" },
                        { label: "Makeup and cosmetics", value: "9ed35a31-7d6f-11e3-bf68-5404a6144203" },
                        { label: "Maintenance and repair services", value: "9ed3a84b-7d6f-11e3-8661-5404a6144203" },
                        { label: "Online gaming", value: "9ed3a84e-7d6f-11e3-87df-5404a6144203" },
                        { label: "Software", value: "9ed3a851-7d6f-11e3-92a4-5404a6144203" },
                        { label: "Monitors and projectors", value: "9ed3a84c-7d6f-11e3-9a6e-5404a6144203" },
                        { label: "Networking", value: "9ed3a84d-7d6f-11e3-9eca-5404a6144203" },
                        { label: "Web hosting and design", value: "9ed3a853-7d6f-11e3-a35d-5404a6144203" },
                        { label: "Training services", value: "9ed3a852-7d6f-11e3-aba3-5404a6144203" },
                        { label: "Peripherals", value: "9ed3a850-7d6f-11e3-ad7d-5404a6144203" },
                        { label: "Desktops, laptops, and notebooks", value: "9ed3a848-7d6f-11e3-af5a-5404a6144203" },
                        { label: "Parts and accessories", value: "9ed3a84f-7d6f-11e3-b1a4-5404a6144203" },
                        { label: "eCommerce services", value: "9ed3a84a-7d6f-11e3-b682-5404a6144203" },
                        { label: "Computer and data processing services", value: "9ed3a847-7d6f-11e3-b813-5404a6144203" },
                        { label: "Department store", value: "9ed44498-7d6f-11e3-87b4-5404a6144203" },
                        { label: "Variety store", value: "9ed4449d-7d6f-11e3-9238-5404a6144203" },
                        { label: "Used and secondhand store", value: "9ed4449c-7d6f-11e3-9843-5404a6144203" },
                        { label: "Miscellaneous store retailer", value: "9ed4449a-7d6f-11e3-a380-5404a6144203" },
                      
    
                            { label: "Peripherals", value: "9ed3a850-7d6f-11e3-ad7d-5404a6144203" },
                            { label: "Desktops, laptops, and notebooks", value: "9ed3a848-7d6f-11e3-af5a-5404a6144203" },
                            { label: "Parts and accessories", value: "9ed3a84f-7d6f-11e3-b1a4-5404a6144203" },
                            { label: "eCommerce services", value: "9ed3a84a-7d6f-11e3-b682-5404a6144203" },
                            { label: "Computer and data processing services", value: "9ed3a847-7d6f-11e3-b813-5404a6144203" },
                            { label: "Department store", value: "9ed44498-7d6f-11e3-87b4-5404a6144203" },
                            { label: "Variety store", value: "9ed4449d-7d6f-11e3-9238-5404a6144203" },
                            { label: "Used and secondhand store", value: "9ed4449c-7d6f-11e3-9843-5404a6144203" },
                            { label: "Miscellaneous store retailer", value: "9ed4449a-7d6f-11e3-a380-5404a6144203" },
                            { label: "Discount store", value: "9ed44499-7d6f-11e3-aa99-5404a6144203" },
                       
                                // ... previous entries
                                { label: "Discount store", value: "9ed44499-7d6f-11e3-aa99-5404a6144203" },
                                { label: "Hobby, toy, and game shops", value: "9ed492bf-7d6f-11e3-87d7-5404a6144203" },
                                { label: "Stationery, printing, and writing paper", value: "9ed492c3-7d6f-11e3-96a8-5404a6144203" },
                                { label: "Vintage and collectibles", value: "9ed492c4-7d6f-11e3-9f0c-5404a6144203" },
                                { label: "Video games and systems", value: "9ed492c5-7d6f-11e3-9fe5-5404a6144203" },
                                { label: "Arts and crafts", value: "9ed492bd-7d6f-11e3-ab0a-5404a6144203" },
                                { label: "Memorabilia", value: "9ed492c0-7d6f-11e3-b1b2-5404a6144203" },
                                { label: "Stamp and coin", value: "9ed492c2-7d6f-11e3-b58e-5404a6144203" },
                                { label: "Music store - instruments and sheet music", value: "9ed492c1-7d6f-11e3-b5a1-5404a6144203" },
                                { label: "Camera and photographic supplies", value: "9ed492be-7d6f-11e3-bf01-5404a6144203" },
                                // ... Add any additional classifications as needed
                            ];
                            
                            export default businessClassifications;
                            