import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transactions: [],
  transactionCount: 0,
  isLoading: false,
  error: null,
};

const transactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {
    addTransaction: (state, action) => {
      state.transactions.push(action.payload);
    },
    addTransactions: (state, action) => {
      action.payload.forEach((newTransaction) => {
        if (!state.transactions.find((t) => t.id === newTransaction.id)) {
          state.transactions.push(newTransaction);
        }
      });
    },
    setTransactions: (state, action) => {
      state.transactions = action.payload;
    },
    setTransactionCount: (state, action) => {
      state.transactionCount = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { addTransaction, addTransactions, setTransactions, setTransactionCount, setLoading, setError } =
  transactionsSlice.actions;
export default transactionsSlice.reducer;
