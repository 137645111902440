import React from "react";
import TransactionsContainer from "../organisms/TransactionsContainer";
import MainLayout from "../../../../components/pageStructures/dashboard/main";

const TransactionsPage = () => {
  return (
    <MainLayout centerContent={false}>
      <TransactionsContainer />
    </MainLayout>
  );
};

export default TransactionsPage;
