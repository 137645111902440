import React from "react";
import { Box, Grid } from "@mui/material";
import Pending from "./pendingTab";
import BankAccount from "./account/main";
import MainLayout from "../../components/pageStructures/dashboard/main";

const BankPage = () => {
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1, mt: 2, mb: 2 }}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={5}>
            <Pending />
          </Grid>
          <Grid item xs={12} md={5}>
            <BankAccount />
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
};

export default BankPage;
