import { generateValidator } from "../../../../../functions/validators/generateValidator";

const validatePersonalDetails = generateValidator([
  { field: 'first_name', type: 'content', required: true, customMessage: 'First name is required.' },
  { field: 'last_name', type: 'content', required: true, customMessage: 'Last name is required.' },
  { field: 'title', type: 'content', required: true, customMessage: 'Title is required.' },
  { field: 'date_of_birth', type: 'dob', required: true, customMessage: 'valid date of birth is required use format DD/MM/YYYY' },
  { 
    field: 'ssn', 
    type: (formData) => formData.isOwner ? 'fullSsn' : 'partialSsn', 
    required: true, 
    customMessage: (formData) => formData.isOwner ? 'Full SSN is required for owners.' : 'Partial SSN is required for non-owners.' 
  },
  { field: 'address', type: 'address', required: true }
]);

export default validatePersonalDetails;
