import React from "react";
import { TextField } from "@mui/material";

const BasicTextField = ({
  label,
  value,
  onChange,
  name,
  error,
  errorMessageAndhelp,
  onFocus,
  multiline = false,
  rows,
  commonStyles,
}) => {
  

  return (
    <TextField
      label={label}
      variant="outlined"
      value={value}
      name={name}
      onChange={onChange}
      onFocus={onFocus}
      fullWidth
      error={error}
      helperText={errorMessageAndhelp}
      multiline={multiline}
      rows={multiline ? rows : undefined}
      sx={commonStyles}
    />
  );
};

export default BasicTextField;
