import masterFakeEndpoint from "../structure";

// Simulate the create-personal-info API
export async function fakeCreatePersonalInfo(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    controller_data: {
      type: "object",
      required: true,
      structure: {
        first_name: { type: "string", required: true },
        last_name: { type: "string", required: true },
        title: { type: "string", required: true },
        date_of_birth: { type: "string", required: true },
        ssn: { type: "string", required: true },
        address: {
          type: "object",
          required: true,
          structure: {
            address: { type: "string", required: true },
            city: { type: "string", required: true },
            state: { type: "string", required: true },
            zip_code: { type: "string", required: true },
          },
        },
      },
    },
    owner_data: {
      type: "array",
      required: true,
      structure: {
        first_name: { type: "string", required: true },
        last_name: { type: "string", required: true },
        date_of_birth: { type: "string", required: true },
        ssn: { type: "string", required: true },
        address: {
          type: "object",
          required: true,
          structure: {
            address: { type: "string", required: true },
            city: { type: "string", required: true },
            state: { type: "string", required: true },
            zip_code: { type: "string", required: true },
          },
        },
      },
    },
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "Duplicate SSN's are not allowed." }, status: 400 },
    { error: { error: "Invalid data provided." }, status: 400 },
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    service: "Controller and owner profiles have been created.",
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
