import  { useState } from "react";

const Settings = () => {
    const [tabValue, setTabValue] = useState('1');

    const handleChange = (event, newValue) => {
        setTabValue(newValue);
      };
    return {
        handleChange,
        tabValue
    }
}

export default Settings;