import React from "react";
import { CardContent } from "@mui/material";
import AccountStatusPanelUI from "./../../ui/molecules/accountStatusPanelUI";
import AccountStatusPanel from "./../../functional/molecules/accountStatusPanel";

const AccountStatusPanelContainer = () => {

    const {
        requirements,
        profileStatus,
        isActive,
         handleIconClick
      } = AccountStatusPanel();

    return (
        <CardContent
            sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: 10,
            mx: "auto",
            mt: 5,
            }}
        >
            <AccountStatusPanelUI
                title="Account Activation"
                statusName="Activation"
                status={isActive ? true : false}
                handleIconClick={handleIconClick}
                requirements={requirements}
            />
            <AccountStatusPanelUI
                title="Discover App Visibility"
                statusName="Visibility"
                status={isActive ? true : false}
                handleIconClick={handleIconClick}
                requirements={profileStatus}
            />
        </CardContent>
    )
}

export default AccountStatusPanelContainer;