import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const RetryDataCheckRouteGuard = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { retry_controller_data, owner_retry_data, retry_business_data } = location.state || {};
  const userStatus = useSelector((state) => state.user.userStatus);

  const hasRequiredData = retry_controller_data || owner_retry_data || retry_business_data;

  useEffect(() => {
    if (!hasRequiredData) {
      // Perform user status-based redirection if required data is missing
      switch (userStatus) {
        case 'notAuthenticated':
          navigate('/sign-in', { replace: true });
          break;
        case 'personalInfo':
          navigate('/personal-info', { replace: true });
          break;
        case 'businessInfo':
          navigate('/business-info', { replace: true });
          break;
        case 'verify':
          navigate('/status', { replace: true });
          break;
        case 'verifyPending':
          navigate('/status/pending', { replace: true });
          break;
        case 'verifySuspended':
          navigate('/status/suspended', { replace: true });
          break;
        default:
          console.log("unknown status - " + userStatus);
          navigate('/sign-in', { replace: true });
          break;
      }
    }
  }, [hasRequiredData, navigate, userStatus]);

  return hasRequiredData ? <>{children}</> : null;
};

export default RetryDataCheckRouteGuard;
