import React, { useState } from "react";
import emailPic from "../../../images/email.png";
import { useNavigate } from "react-router-dom";
import { Typography, Link, Grid } from "@mui/material";
import PageLayout from "../../../components/pageStructures/authPages/master";
import useFullFetchFeedback from "../../../functions/apiFetchers/fullPageLoaders";
import { validateField } from "../../../functions/validators/master";
import GeneralInput from "../../../components/atoms/inputs/GeneralInput";

const EmailPage = ({ onNext }) => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  const linkStyle = { cursor: "pointer", textDecoration: "none" };
    const handleSignIn = () => {
    navigate("/sign-in");
  };


  const handleSubmit = async (event) => {
     const errorValidation = [{ field: 'email', type: 'email', required: true}]

    await fetchFeedback({
      endpoint: "merchant/email/",
      requestType: "POST",
      auth: false, 
      data: { email },
      setError: setError,
      validationRules: errorValidation,
      loaderMessage: "Verifying Email...",


      
      onSuccess: (responseData) => {
        navigate("/code-verification", {
          state: { profile_pk: responseData.profile_pk },
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return (
    <PageLayout
      sideImageUrl={emailPic}
      handleSubmit={handleSubmit}
      title={"Enter Email"}
      description={
        "Your email ensures secure transactions and keeps you informed about account activities and updates."
      }
      currentStep={1}
      navigate={navigate}
    >
      <GeneralInput
        type={"text"}
        label="Email Address"
        name={"email"}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        error={!!error}
        errorMessageAndhelp={error.email || ""}
      />
 <Grid
      container
      spacing={2}
      justifyContent="center" // Align content to the center
      sx={{ mt: .2, mb: 3 }}
    >
      {/* Sign In Link */}
      <Grid item xs={12} sm={6} textAlign="center"> {/* Align the grid item to the center */}
        <Typography variant="body2">
          Already have an account?{" "}
          <Link onClick={handleSignIn} sx={linkStyle}>
            Sign In
          </Link>
        </Typography>
      </Grid>
    </Grid>
    </PageLayout>
  );
};

export default EmailPage;
