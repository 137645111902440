import masterFakeEndpoint from "../../structure";

// Simulate the register-account API
export async function fakeRegisterAccount(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    username: { type: "string", required: true },
    password: { type: "string", required: true },
  };

  // Define potential errors
  const errorArray = [
    { error: { username: "Username already exists." }, status: 400 },
   
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    register_data: {
      username: data.username,  // Echo back the username
      register_pin: Math.floor(1000 + Math.random() * 9000),  // Simulate a random PIN
      register_count: 1,  // Simulate a registration count, could be dynamic
    },
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
