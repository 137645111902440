import React, { useState, useContext } from "react";
import {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  Chip,
  Button,
  Box,
} from "@mui/material";
import InfoIconButton from "../../components/molecules/dialogues/info";

import GeneralButton from "../../components/atoms/buttons/GeneralButton";
import { useSelector } from "react-redux";
import { selectAllStoreLocations } from "../../features/stores/storesSelectors";

const StoreProfilesComponent = ({
  handleLocationClick,
  setIsAddLocationDialogOpen,
}) => {
  const storeLocations = useSelector(selectAllStoreLocations);

  return (
    <>
      <Paper
        elevation={3}
        sx={{
          maxWidth: 600,
          margin: "auto",
          mt: 4,
          p: 2,
          position: "relative",
        }}
      >
        <Typography variant="h4" gutterBottom textAlign="center">
          Store Locations
        </Typography>
        <Typography
          variant="body1"
          sx={{ mt: 2, textAlign: "center", width: "80%", margin: "auto" }}
        >
          By adding locations, once approved, they will appear in the consumer
          app under Discover, driving customers directly to your stores.
        </Typography>

        {storeLocations.length > 0 ? (
          <List>
            {storeLocations.map((location, index) => (
              <ListItem
                key={location.id}
                button
                onClick={() => handleLocationClick(location)}
                secondaryAction={
                  <Chip
                    sx={{ width: 90 }}
                    variant="outlined"
                    label={location.status}
                    size="small"
                    color={
                      location.status === "Verified"
                        ? "success"
                        : location.status === "Pending"
                          ? "warning"
                          : "default"
                    }
                  />
                }
              >
                <ListItemText
                  primary={location.name}
                  secondary={location.address}
                />
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography
            variant="body1"
            textAlign="center"
            color="textSecondary"
            sx={{ my: 4 }}
          >
            Your store locations will appear here. Add a new location to start
            showcasing your stores.
          </Typography>
        )}
        <Box sx={{ alignContent: "center" }}>
          <GeneralButton
            label="Add Location to Discover"
            onClick={() => setIsAddLocationDialogOpen(true)}
            type="PlainText"
            center={true}
          />
        </Box>
        <InfoIconButton
          title="About Adding Locations"
          info="By adding locations after we confirm it is a real location, we will display it to consumers in their app to drive them to your store. This verification process is crucial for maintaining the quality and trustworthiness of locations displayed to users."
        />
      </Paper>
    </>
  );
};

export default StoreProfilesComponent;
