import React, { useState } from "react";
import CustomInputDialog from "../../../../../../components/molecules/dialogues/customInput";

import { updateOwnerData } from "./functions/setOwner";
import GeneralInput from "../../../../../../components/atoms/inputs/GeneralInput";

// dialogData is in this structure {displayName: {serverName: value}}

const ChangeDataDialogue = ({
  open,
  handleClose,
  dialogData,
  selectedRetryData,
}) => {
  const { oldSetData, newSetData, ownerId } = selectedRetryData;
  const [formData, setFormData] = useState(dialogData);

  /**
   * Handles changes to input fields and updates formData state.
   *
   * @param {String} displayName - The display name of the input field.
   * @param {String} serverName - The server name (key) for the input field value.
   * @param {Any} value - The new value to be set.
   */
  const handleInputChange = (displayName, serverName, value) => {
    setFormData((prevState) => ({
      [displayName]: { [serverName]: value },
    }));
  };

  const handleSubmit = () => {
    // Example initial data structure for oldSetData when ownerId is present
    // {
    //     owners: [
    //         { id: '123', displayName1: { serverName1: 'oldValue1' } },
    //         { id: '456', displayName2: { serverName2: 'oldValue2' } }
    //     ]
    // }
    //
    // Example initial data structure for newSetData when ownerId is present
    // {
    //     owners: [
    //         { id: '123', serverName1: 'oldValue1' },
    //         { id: '456', serverName2: 'oldValue2' }
    //     ]
    // }
    //
    // Example formData (updating serverName1 for owner with id '123')
    // {
    //     displayName1: { serverName1: 'newValue' }
    // }
    //
    // If ownerId is '123', the final updated structure will be:
    //
    // Updated oldSetData
    // {
    //     owners: [
    //         { id: '123', displayName1: { serverName1: 'newValue' } },
    //         { id: '456', displayName2: { serverName2: 'oldValue2' } }
    //     ]
    // }
    //
    // Updated newSetData
    // {
    //     owners: [
    //         { id: '123', serverName1: 'newValue' },
    //         { id: '456', serverName2: 'oldValue2' }
    //     ]
    // }
    //
    // Example initial data structure for oldSetData when ownerId is not present
    // {
    //     displayName1: { serverName1: 'oldValue1' },
    //     displayName2: { serverName2: 'oldValue2' }
    // }
    //
    // Example initial data structure for newSetData when ownerId is not present
    // {
    //     serverName1: 'oldValue1',
    //     serverName2: 'oldValue2'
    // }
    //
    // Example formData (updating serverName1)
    // {
    //     displayName1: { serverName1: 'newValue' }
    // }
    //
    // After update, the structure will be:
    //
    // Updated oldSetData
    // {
    //     displayName1: { serverName1: 'newValue' },
    //     displayName2: { serverName2: 'oldValue2' }
    // }
    //
    // Updated newSetData
    // {
    //     serverName1: 'newValue',
    //     serverName2: 'oldValue2'
    // }

    // Update oldSetData with new formData, keeping other data intact

    oldSetData((prevState) => {
      let updatedData = { ...prevState };
      if (ownerId) {
        // If ownerId is present, update the owners list
        updatedData = updateOwnerData(prevState || [], formData, ownerId);
      } else {
        // If ownerId is not present, merge formData directly into oldSetData
        const [displayName, value] = Object.entries(formData)[0];
        const serverName = Object.keys(value)[0];
        updatedData[displayName] = {
          ...updatedData[displayName],
          [serverName]: value[serverName],
        };
      }
      return updatedData;
    });

    newSetData((prevState) => {
      let updatedData = { ...prevState };

      if (ownerId) {
        // If ownerId is present, update the owners list
        updatedData = updateOwnerData(prevState || [], formData, ownerId, true);
      } else {
        // If ownerId is not present, merge formData directly into newSetData
        const [displayName, value] = Object.entries(formData)[0];
        const serverName = Object.keys(value)[0];
        updatedData[serverName] = value[serverName];
      }
      return updatedData;
    });

    // Close the dialog
    handleClose();
  };

  // Function to format address object into a single string
  const formatAddress = (address) => {
    if (typeof address === "object" && address !== null) {
      return Object.values(address).join(", ");
    }
    return address;
  };

  // Render the dialog
  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      title={"Update your information"}
      subTitle={"Update your information"}
      onSubmit={handleSubmit}
    >
      {Object.entries(formData).map(([displayName, serverData]) => {
        const serverName = Object.keys(serverData)[0];
        let value = serverData[serverName];
        let inputType;

        switch (serverName) {
          case "address":
            inputType = "address";
            value = formatAddress(value); // Format address value
            break;
          case "ssn":
            inputType = "ssn";
            break;
          case "date_of_birth":
            inputType = "date";
            break;
          default:
            inputType = "text";
            break;
        }

        return (
          <GeneralInput
            key={displayName}
            type={inputType}
            label={displayName}
            name={serverName}
            value={value}
            fullSSN={true}
            onChange={(e) =>
              handleInputChange(displayName, serverName, e.target.value)
            }
          />
        );
      })}
    </CustomInputDialog>
  );
};

export default ChangeDataDialogue;
