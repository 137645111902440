import React, { useState } from "react";
import { Button, Typography, Grid } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DaysDialog from "./dialog";
import { updateDashboardMetrics, setDuration } from "../../../features/dashboard/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import useFetchFeedback from "../../../functions/apiFetchers/fullPageLoaders";
import { selectDuration } from "../../../features/dashboard/dashboardSelectors";


const DataDurationButton = () => {
  const duration = useSelector(selectDuration);
  const [dialogOpen, setDialogOpen] = useState(false);
  const dispatch = useDispatch();
  const fetchFeedback = useFetchFeedback();


  const handleSubmitDuration = async (newDuration) => {
  
    await fetchFeedback({
      endpoint:`merchant/tabs/data/?days=${newDuration}`,
      loaderType: "dialogueLoader",
      requestType: "GET",
      loaderMessage: "Updating duration...",
      successMessage: "Duration updated successfully!",
      onSuccess: (responseData) => {
        dispatch(updateDashboardMetrics(responseData));
        dispatch(setDuration(newDuration))
      },

    });
  };


  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        sx={{ mt: "-4%", mb: 2 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", textAlign: "center", mb: 1 }}
          >
            Data For Trailing {duration} Days
          </Typography>
          <Button
            variant="text"
            endIcon={<EditIcon />}
            onClick={() => setDialogOpen(true)}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textTransform: "none",
              margin: "auto",
              size: "small",
            }}
          >
            {`Edit Days Shown`}
          </Button>
        </Grid>
      </Grid>
      <DaysDialog
        open={dialogOpen}
        setOpen={setDialogOpen}
        onSubmit={handleSubmitDuration}
      />
    </>
  );
};

export default DataDurationButton;
