import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  dialogs: {
    info: { open: false, message: '', title: 'Information' },
    success: { open: false, message: '', title: '' },
    // Add other dialog types if needed, e.g., error, warning, etc.
  }
};

const dialogSlice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    showDialog(state, action) {
      const { dialogType, message, title } = action.payload;
      if (state.dialogs[dialogType]) { // Check if dialogType exists
        state.dialogs[dialogType].open = true;
        state.dialogs[dialogType].message = message;
        if (title) {
          state.dialogs[dialogType].title = title;
        }
      } else {
        console.error(`Unknown dialog type: ${dialogType}`);
      }
    },
    hideDialog(state, action) {
      const dialogType = action.payload;
      if (state.dialogs[dialogType]) { // Check if dialogType exists
        state.dialogs[dialogType].open = false;
        state.dialogs[dialogType].message = '';
        state.dialogs[dialogType].title = '';
      } else {
        console.error(`Unknown dialog type: ${dialogType}`);
      }
    },
  },
});

export const { showDialog, hideDialog } = dialogSlice.actions;

export default dialogSlice.reducer;
