import React, { useState } from "react";
import codeImage from "../../../images/code.png";
import { useLocation, useNavigate } from "react-router-dom";
import PageLayout from "../../../components/pageStructures/authPages/master";
import useFullFetchFeedback from "../../../functions/apiFetchers/fullPageLoaders";
import { validateField } from "../../../functions/validators/master";
import GeneralInput from "../../../components/atoms/inputs/GeneralInput";

const EmailCodePage = ({ onNext }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const profile_pk = location.state?.profile_pk;
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = async (event) => {
    const errorValidation = [{ field: 'otp', type: 'number', required: true}]

      await fetchFeedback({
        endpoint: "confirm-email-otp/",
        requestType: "POST",
        auth: false, 
        data: { otp: code, profile_pk: profile_pk },
        setError: setError,
        validationRules: errorValidation,
        loaderMessage: "Verifying Email...",
        // On success, navigate to the code-verification page
        onSuccess: (responseData) => {
          navigate("/account-info", {
            state: { profile_pk: profile_pk, email: responseData.email },
          });
        },
        onError: (error) => {
          setError(error);
        }
      });
    }


  return (
    <PageLayout
      sideImageUrl={codeImage}
      handleSubmit={handleSubmit}
      title={"Verification Code"}
      description={
        "Please enter the code sent to your email for security purposes."
      }
      currentStep={2}
    >
      <GeneralInput
        type={"text"}
        label="Enter Code"
        name={"code"}
        value={code}
        onChange={(e) => setCode(e.target.value)}
        error={!!error}
        errorMessageAndhelp={error.otp || ""}
      />
    </PageLayout>
  );
};

export default EmailCodePage;
