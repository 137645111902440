// src/features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentUser: "Yechiel Gartenhaus",
  status: "notAuthenticated", // Default status; adjust as needed
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    // Additional user-related reducers can be added here as needed
  },
});

export const { setCurrentUser, setStatus } = userSlice.actions;
export default userSlice.reducer;
