// UserOptions.js
import React from "react";
import useUserOptions from "../../functionality/molecules/useUserOptions";
import UserOptionsUI from "../../ui/molecules/UserOptionsUI";
import CredentialManager from "./manager";


const UserOptions = () => {
  const {
    credentialType,
    handleSignUp,
    handleResetPassword,
    handleResetUsername,
    handleCloseDialog,
  } = useUserOptions();

  

  return (
    <UserOptionsUI
      handleSignUp={handleSignUp}
      handleResetPassword={handleResetPassword}
      handleResetUsername={handleResetUsername}
      credentialType={credentialType}
    >
      {credentialType && (
        <CredentialManager
          type={credentialType}
          open={Boolean(credentialType)}
          handleClose={handleCloseDialog}
        />
      )}
    </UserOptionsUI>
  );
};

export default UserOptions;
