import { useNavigate, useLocation } from 'react-router-dom';

export const useSideNav = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onTabClick = (route) => {
    navigate(route);
  };

  return {
    onTabClick,
  };
};
