import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import useFullFetchFeedback from "../../../functions/apiFetchers/fullPageLoaders";
import { validateField } from "../../../functions/validators/master";
import OwnerApplication from "./owner/manager";
import PersonalInfoForm from "./personal/personalInfoForm";
import { setStatus } from "../../../features/user/userSlice";
import { useDispatch } from "react-redux";

const PersonalInfoPage = () => {
  const [value, setValue] = useState(0); // Tab value
  const [personalInfo, setPersonalInfo] = useState({
    first_name: "",
    last_name: "",
    title: "",
    date_of_birth: "",
    address: { address: "", city: "", state: "", zip_code: "" },
    ssn: "",
    isOwner: false,
  });
  const [errors, setError] = useState("");
  const [controllerIndex, setControllerIndex] = useState(null);
  const [owners, setOwners] = useState([]);
  const fetchFeedback = useFullFetchFeedback();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    const { name, type, checked, value } = e.target;
    setPersonalInfo((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
 


    const errors = validateField("list", owners);
    if (errors) {
      return;

    } else {
      await fetchFeedback({
        endpoint: "merchant/personal_info/",
        data: { owners, personal_info: personalInfo },
        loaderMessage: "Submitting personal details...",
        requestType: "POST",
        onSuccess: (responseData) => {
        
        
          dispatch(setStatus('businessInfo'))
          navigate("/business-info");
        },
        onError: (error) => {
         
          setError(error);
        },
      });
    }
  };

  return (
    <>
      {value === 0 && (
        <PersonalInfoForm
          personalInfo={personalInfo}
          handleInputChange={handleInputChange}
          controllerIndex={controllerIndex}
          setControllerIndex={setControllerIndex}
          setOwners={setOwners}
          setValue={setValue}
        />
      )}
      {value === 1 && (
        <OwnerApplication
          owners={owners}
          setOwners={setOwners}
          navigateBackToController={() => {
            setValue(0);
          }}
          controllerIndex={controllerIndex}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
};

export default PersonalInfoPage;
