import { TextField } from "@mui/material";
import React, { useState } from "react";
import CustomInputDialog from "../../../components/molecules/dialogues/customInput";

const DaysDialog = ({ open, setOpen, onSubmit }) => {
  const [days, setDays] = useState("");

  const handleClose = () => {
    setOpen(false);
  };

  const handleEnter = () => {
    onSubmit(parseInt(days, 10));
    setOpen(false);
  };

  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleEnter}
      title="Enter Days"
    >
      <TextField
        autoFocus
        margin="dense"
        id="days"
        label="Number of Days"
        type="number"
        fullWidth
        variant="outlined"
        value={days}
        onChange={(e) => setDays(e.target.value)}
      />
    </CustomInputDialog>
  );
};

export default DaysDialog;
