import React, { useContext, useState } from "react";
import Box from "@mui/material/Box"; // Import Box component from MUI
import RegisterControlTab from "./input";
import RegisterSettingsDisplay from "./data/listData";
import MainLayout from "../../components/pageStructures/dashboard/main";
import { useDispatch, useSelector } from "react-redux";
import useFetchFeedback from "../../functions/apiFetchers/fullPageLoaders";
import { updateMasterRegister, updateRegisterData } from "../../features/register/registerSlice";
import { selectMasterRegister } from "../../features/register/registerSelectors";

import { generateValidator } from "../../functions/validators/generateValidator";

const validateCredentialDetails = generateValidator([
  {
    field: "username",
    type: "content",
    required: true,
    customMessage: "Username is required.",
  },
  {
    field: "password",
    type: "content",
    required: true,
    customMessage: "Password is required.",
  },
]);

const Register = () => {
  const masterRegister = useSelector(selectMasterRegister);
  const dispatch = useDispatch();
  const fetchFeedback = useFetchFeedback();



  const handleSetupMasterRegisterSubmit = async (username, password, setError) => {
    const errorValidation = [{ field: 'username', type: 'content', required: true}, { field: 'password', type: 'password', required: true}]

    await fetchFeedback({
      endpoint:`merchant/register-account/`,
      loaderType: "dialogueLoader",
      data: { username, password },
      requestType: "POST",
      validationRules: errorValidation,
      loaderMessage: "Updating registration...",
      successMessage: "Registration successful!",
      setError, // function to handle validation errors
      onSuccess: (responseData) => {
        dispatch(updateRegisterData(responseData));
        dispatch(updateMasterRegister(true));
      
      },
      
    });
  };

  return (
    <MainLayout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: "auto",
          alignItems: "center",
        }}
      >
        {masterRegister ? (
          <RegisterSettingsDisplay onClick={handleSetupMasterRegisterSubmit} />
        ) : (
          <RegisterControlTab onSignUp={handleSetupMasterRegisterSubmit} />
        )}
      </Box>
    </MainLayout>
  );
};

export default Register;
