import React from "react";
import { Box } from "@mui/material";

import DataDurationIndicator from "./duration/main";
import Tabs from "./tabs/tabs";
import TransactionsTable from "./transactionTable";
import MainLayout from "../../components/pageStructures/dashboard/main";

const DashboardPage = () => {
  return (
    <MainLayout>
      <Box sx={{ flexGrow: 1 }}>
        <DataDurationIndicator />
        <Tabs />

        <Box sx={{ mt: 4 }}>
          <TransactionsTable />
        </Box>
      </Box>
    </MainLayout>
  );
};

export default DashboardPage;
