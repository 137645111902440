import React from 'react';
import { Button, Stack } from '@mui/material';

const nameMapping = {
  personalInfo: 'Personal Information',
  businessInfo: 'Business Information',
};

const RetryStack = ({ data, onItemClick }) => {
  console.log(data)

  const renderButton = (category, item, id = null) => {
    const displayName = category in nameMapping 
    ? nameMapping[category] 
    : `Owner - ${item['First Name'].first_name} ${item['Last Name'].last_name}`;

    return (
      <Button
      key={id !== null ? `${category}-${id}` : category}
      fullWidth
      onClick={() => onItemClick({ category, item, id })}
      sx={{
        justifyContent: "center", // Center the content
        bgcolor: "background.paper",
        border: 0.2,
        borderColor: "grey.300",
        py: 1,
        px: 2,
        textAlign: "center", // Ensure text is centered
        textTransform: "none",
        borderRadius: "0",
        boxShadow: "none",
        ":hover": {
          bgcolor: "grey.100",
          boxShadow: "none",
        },
      }}
    >
      {displayName}
    </Button>
    );
  };

  return (
    <Stack spacing={2}>
      {Object.entries(data).map(([category, value]) => (
        Array.isArray(value)
          ? value.map((item, index) => value != null && renderButton(category, item, item['Id']?.id))
          : value != null && renderButton(category, value)
      ))}
    </Stack>
  );
  
};

export default RetryStack;
