import React from 'react';
import GoogleMapsAutocomplete from './components/address';
import AutocompleteInput from './components/AutocompleteInput';
import BasicTextField from './components/BasicTextField';
import DateFormattedTextField from './components/dateField';
import EINFormattedTextField from './components/ein';
import PasswordTextField from './components/password';
import SSNFormattedTextField from './components/ssnField';


  /**
 * `GeneralInput` is a reusable input component that dynamically renders different input types.
 * Supported input types include:
 * - `autocomplete`: Uses the `AutocompleteInput` component.
 * - `text`: Uses the `BasicTextField` component.
 * - `address`: Uses the `GoogleMapsAutocomplete` component.
 * 
 * {Object} props - Properties passed to the component.
 *  props.type - Type of input to render.
 *  [props.error] - If true, marks the input field as erroneous.
 *  [props.label] - Label for the input field.
 *  [props.onChange] - Function to call when the input value changes.
 *  [props.name] - Name attribute for the input field.
 *  [props.helperText] - Additional helper text for the input field.
 *  {JSX.Element|null} - The rendered input field, or null if the type is unsupported.
 */

const GeneralInput = (props) => {
    const { type, error } = props;
    
    const commonStyles = {
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": {
            borderColor: error ? 'red' : "#44C952",
          },
        },
        "& .MuiInputLabel-outlined.Mui-focused": {
          color: error ? 'red' : "#44C952",
        },
      };


    switch (type) {
        case 'autocomplete':
            return <AutocompleteInput {...props} commonStyles={commonStyles}/>;
        case 'text':
            return <BasicTextField {...props} commonStyles={commonStyles}/>;
        case 'password':
            return <PasswordTextField {...props} commonStyles={commonStyles}/>;
        case 'date':
            return <DateFormattedTextField {...props} commonStyles={commonStyles}/>;
        case 'ssn':
            return <SSNFormattedTextField {...props} commonStyles={commonStyles}/>;
        case 'ein':
            return <EINFormattedTextField {...props} commonStyles={commonStyles}/>;
        case "address":
            return <GoogleMapsAutocomplete {...props} commonStyles={commonStyles} />;
        default:
            return null;  // Render nothing if type is not recognized
    }
};

export default GeneralInput;
