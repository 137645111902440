// src/features/bank/bankSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bankData: {
    accountNumber: "987",
    accountName: "Kuda Bank",
    balance: "$112,000.00",
    lastChecked: "Sep 01 at 2:24 PM",
    logo: "",
  },
  pendingBalance: 374,
};

const bankSlice = createSlice({
  name: 'bank',
  initialState,
  reducers: {
    setBankData: (state, action) => {
      state.bankData = {...action.payload };
    },
    setPendingBalance: (state, action) => {
      state.pendingBalance = action.payload;
    },
  },
});

export const { setBankData, setPendingBalance } = bankSlice.actions;
export default bankSlice.reducer;
