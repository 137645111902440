import React, { useState, useEffect } from "react";
import { Box, Container, useTheme, useMediaQuery } from "@mui/material";

import { useDispatch, useSelector } from "react-redux";



import SideNavContainer from "../../organisms/sideNav/container/SideNavContainer ";
import TopBarContainer from "../../organisms/topBar/container/TopBarContainer";

const MainLayout = ({ children, centerContent = true }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [drawerOpen, setDrawerOpen] = useState(isMdUp);






  useEffect(() => {
    setDrawerOpen(isMdUp);
  }, [isMdUp]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      <SideNavContainer drawerOpen={drawerOpen} toggleDrawer={toggleDrawer} />
      <Box
        component="main"
        sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
      >
        <TopBarContainer  toggleDrawer={toggleDrawer} />
        <Container
          maxWidth="lg"
          sx={{
            mt: centerContent ? "auto" : 4,
            mb: centerContent ? "auto" : 4,
            transform: centerContent ? "translateY(-8%)" : "none",
          }}
        >
          {children}
        </Container>
      </Box>
    </Box>
  );
};

export default MainLayout;
