// useAccountInfo.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { setStatus } from "../../../../features/user/userSlice";



const useAccountInfo = () => {
  const [accountInfo, setAccountInfo] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const fetchFeedback = useFullFetchFeedback();
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    const errorValidation = [
      { field: "username", type: "content", required: true },
      { field: "password", type: "password", required: true },
    ];

    await fetchFeedback({
      endpoint: "merchant/sign-in/",
      data: { ...accountInfo },
      auth: false,
      requestType: "POST",
      validationRules: errorValidation,
      loaderMessage: "Signing in to Account...",

      onSuccess: (responseData) => {
        localStorage.setItem("token", responseData.token);
        switch (responseData.status) {
          case "personalInfo":
            dispatch(setStatus("personalInfo"));
            navigate("/personal-info");
            break;
          case "businessInfo":
            dispatch(setStatus("businessInfo"));
            navigate("/business-info");
            break;
          case "verify":
            dispatch(setStatus("verify"));
            navigate("/status");
            break;
          case "complete":
            dispatch(setStatus("complete"));
            navigate("/dashboard");
            break;
          default:
            break;
        }
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return {
    accountInfo,
    setAccountInfo,
    error,
    handleSubmit,
  };
};

export default useAccountInfo;
