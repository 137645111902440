import React from "react";
import { Button, Stack, IconButton, Box } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import PageLayout from "../../../../components/pageStructures/authPages/master";
import personalInfoImage from "../../../../images/personal.png";

function OwnerManager({
  owners,
  onAdd,
  onDelete,
  onOwnerClick,
  handleSubmit,
  navigateBackToController,
}) {
  // Adjust the handleDeleteClick to return another function
  // This curried function avoids creating an additional anonymous function on each render
  const handleDeleteClick = (ownerId) => (e) => {
    e.stopPropagation();
    onDelete(ownerId);
  };

  return (
    <>
      <PageLayout
        sideImageUrl={personalInfoImage}
        handleSubmit={handleSubmit}
        disabled={owners.length === 0}
        title={"All Owner"}
        description={
          "Add in every owner that owns at least 25% of the bussiness"
        }
        currentStep={3}
        onBack={navigateBackToController}
        backArrow={true}
       
      >
        <Stack spacing={0}>
          {owners.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "background.paper",
                border: 1,
                borderColor: "grey.300",
                py: 1,
                px: 2,
              }}
            >
              No owners added
            </Box>
          ) : (
            owners.map((owner) => (
              <Button
                key={owner.id}
                fullWidth
                sx={{
                  justifyContent: "space-between",
                  bgcolor: "background.paper",
                  border: 0.2,
                  borderColor: "grey.300",
                  py: 1,
                  px: 2,
                  textTransform: "none",
                  borderRadius: "0",
                  boxShadow: "none", // Remove shadow from buttons
                  ":hover": {
                    bgcolor: "grey.100",
                    boxShadow: "none", // Ensure the shadow does not re-appear on hover
                  },
                }}
                onClick={() => onOwnerClick(owner.id)}
              >
                {owner.name}
                <IconButton onClick={handleDeleteClick(owner.id)} size="large">
                  <DeleteIcon />
                </IconButton>
              </Button>
            ))
          )}
          <Button
            fullWidth
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={onAdd}
            sx={{
              bgcolor: "grey.200",
              color: "black",
              ":hover": {
                bgcolor: "grey.300",
                boxShadow: "none", // Ensure the shadow does not re-appear on hover
              },
              border: 1,
              borderTop: 0,
              borderColor: "grey.300",
              borderRadius: "0", // Set the top radius to zero for the button
              boxShadow: "none", // Remove shadow from the "Add Owner" button
            }}
          >
            Add Owner
          </Button>
        </Stack>
      </PageLayout>
    </>
  );
}

export default OwnerManager;
