import React from "react";
import GeneralInput from "../../../../components/atoms/inputs/GeneralInput";
import CustomInputDialog from "../../../../components/molecules/dialogues/customInput";

const UpdateCredentialDialogUI = ({
  type,
  open,
  handleClose,
  credential,
  error,
  setCredential,
  handleSubmit,
  renderTextFieldLabel,
  renderTextFieldType,
}) => {
  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleSubmit}
      title={`Update ${type.charAt(0).toUpperCase() + type.slice(1)}`}
      subTitle={`Please enter your new ${type}.`}
    >
      <GeneralInput
        type={renderTextFieldType()}
        label={renderTextFieldLabel()}
        name={renderTextFieldLabel()}
        value={credential}
        onChange={(e) => setCredential(e.target.value)}
        error={!!error}
        errorMessageAndhelp={error || ""}
      />
    </CustomInputDialog>
  );
};

export default UpdateCredentialDialogUI;
