import { generateValidator } from "../../../../../functions/validators/generateValidator";

const validateOwnerDetails = generateValidator([
  { field: 'first_name', type: 'content', required: true, customMessage: 'First name is required.' },
  { field: 'last_name', type: 'content', required: true, customMessage: 'Last name is required.' },
  { field: 'date_of_birth', type: 'dob', required: true },
  { field: 'ssn', type: 'fullSsn', required: true, customMessage: 'Full SSN is required.' },
  { field: 'address', type: 'address', required: true }
]);

export default validateOwnerDetails;
