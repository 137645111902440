import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import PageLayout from "../../../../components/pageStructures/authPages/master";
import validateOwnerDetails from "./function/validate";
import personalInfoImage from "../../../../images/personal.png";
import { updateOwnersList } from "./function/saveOwner";
import { handleInputChange } from "../../../../functions/general/setData";
import DynamicForm from "../../../../components/atoms/inputs/dynamic";
const OwnerInfoForm = ({ activeOwner, setOwners, handleSubmit, navigateBackToOwnerManager }) => {
  const [owner, setOwner] = useState({
    id: "",
    first_name: "",
    last_name: "",
    date_of_birth: "",
    ssn: "",
    address: { address: "", city: "", state: "", zip_code: "" },
  });
  const [errors, setError] = useState("");

  useEffect(() => {
    if (activeOwner) {
      setOwner((prevOwner) => ({
        ...activeOwner,
      }));
    }
  }, [activeOwner]);

  const inputConfigs = [
    {
      type: "text",
      label: "First Name",
      name: "first_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "text",
      label: "Last Name",
      name: "last_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "date",
      label: "Date of Birth",
      name: "date_of_birth",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "ssn",
      label: "SSN",
      name: "ssn",
      grid: { xs: 12, sm: 6 },
      props: { fullSSN: true },
    },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
  ];


  const onClick = () => {
    const errors = validateOwnerDetails(owner);
    if (Object.keys(errors).length !== 0) {
      setError(errors);
      return;
    }
    setOwners((prevState) => updateOwnersList(prevState, owner));

    handleSubmit();
  };

  return (
    <PageLayout
      sideImageUrl={personalInfoImage}
      handleSubmit={onClick}
      title={"Owner Details"}
      description={"Please fill in the businesses controllers details."}
      currentStep={3}
      onBack={navigateBackToOwnerManager}
      backArrow={true}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          width: "100%",
        }}
      >
        <DynamicForm
          fieldConfig={inputConfigs}
          formData={owner}
          handleInputChange={(e) => handleInputChange(e, setOwner)}
          errors={errors}
        />
      </Box>
    </PageLayout>
  );
};

export default OwnerInfoForm;
