import statusDetails from "./../constants/statusDetails";
import { showDialog } from "../../../../features/dialog/dialogSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectMasterRegister } from "../../../../features/register/registerSelectors";
import { selectBankData } from "../../../../features/banking/bankSelectors";
import { selectAllStoreLocations } from "../../../../features/stores/storesSelectors";
import { selectCurrentCashback } from "../../../../features/cashback/cashbackSelectors";

const AccountStatusPanel  = () => { 
    const masterRegister = useSelector(selectMasterRegister);
    const bankData = useSelector(selectBankData);
  
    const hasBankAccount = bankData && bankData.accountNumber;
    const currentCashback = useSelector(selectCurrentCashback);
    const storeLocations = useSelector(selectAllStoreLocations);
    const dispatch = useDispatch();

    // Conditions based on data
    const isActive =
    parseFloat(currentCashback) > 0 && hasBankAccount && masterRegister;
    const requirements = {
    "Register Setup": masterRegister,
    "Cashback Set": parseFloat(currentCashback) > 0,
    "Bank Connected": hasBankAccount,
    };
    const isShownInDiscover = storeLocations.some(
    (location) => location.status === "Verified"
    );

    // Determine profile upload and approval status (assuming there are fields to check this which aren't specified)
    const profileStatus = {
        "Profile Uploaded": storeLocations.length > 0,
        "Profile Approved": storeLocations.some(
        (location) => location.status === "Verified"
        ),
    };

    const handleIconClick = (statusName, statusValue) => {
        // Determine the subkey based on statusValue
        const subKey = statusValue ? "active" : "inactive";
        // Retrieve the detail object using statusName and subKey
        const detail = statusDetails[statusName][subKey];

        // Update dialog content and open the dialog
        dispatch(
        showDialog({
            dialogType: "info",
            message: detail.info,
            title: detail.title,
        })
        );
    };
    return {
        requirements,
        profileStatus,
        isActive,
        handleIconClick
    }
}


export default AccountStatusPanel;