import masterFakeEndpoint from "../../structure";

// Simulate the User Retry Verification API
export async function fakeUserRetryVerification(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    controller_data: {
      type: "object",
      required: true,
      structure: {
        first_name: { type: "string", required: false },
        last_name: { type: "string", required: false },
        title: { type: "string", required: false },
        date_of_birth: { type: "string", required: false },
        ssn: { type: "string", required: false },
        address: {
          type: "object",
          required: false,
          structure: {
            address: { type: "string", required: true },
            city: { type: "string", required: true },
            state: { type: "string", required: true },
            zip_code: { type: "string", required: true },
          },
        },
      },
    },
    owner_data: {
      type: "array",
      required: true,
      structure: {
        first_name: { type: "string", required: false },
        last_name: { type: "string", required: false },
        date_of_birth: { type: "string", required: false },
        ssn: { type: "string", required: false },
        address: {
          type: "object",
          required: true,
          structure: {
            address: { type: "string", required: true },
            city: { type: "string", required: true },
            state: { type: "string", required: true },
            zip_code: { type: "string", required: true },
          },
        },
      },
    },
    business_data: {
      type: "object",
      required: true,
      structure: {
        business_name: { type: "string", required: false },
        business_classification: { type: "string", required: false },
        business_classification_id: { type: "string", required: false },
        business_structure: { type: "string", required: false },
        address: {
          type: "object",
          required: false,
          structure: {
            address: { type: "string", required: true },
            city: { type: "string", required: true },
            state: { type: "string", required: true },
            zip_code: { type: "string", required: true },
          },
        },
        doing_business_as: { type: "string", required: false },
        website: { type: "string", required: false },
      },
    },
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "You must provide a valid 9-digit SSN." }, status: 400 },
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    service: "Verification information has been submitted to Dwolla.",
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
