import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const ProfilePkCheckRouteGuard = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const profile_pk = location.state?.profile_pk;

  useEffect(() => {
    if (!profile_pk) {
      navigate('/email', { replace: true }); // Redirect to the email page if profile_pk is missing
    }
  }, [profile_pk, navigate]);

  return profile_pk ? <>{children}</> : null;
};

export default ProfilePkCheckRouteGuard;
