import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  cashbackHistory: [],
  currentCashback: 0,
  isLoading: false,
  error: null,
  success: false,
};

const cashbackSlice = createSlice({
  name: 'cashback',
  initialState,
  reducers: {
    addCashbackHistory: (state, action) => {
      state.cashbackHistory.push(action.payload);
    },
    setCashbackHistory: (state, action) => {
      state.cashbackHistory = action.payload;
    },
    setCurrentCashback: (state, action) => {
      state.currentCashback = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
    },
  },
});

export const {
  addCashbackHistory,
  setCurrentCashback,
  setCashbackHistory,
  setLoading,
  setError,
  setSuccess,
} = cashbackSlice.actions;

export default cashbackSlice.reducer;
