import masterFakeEndpoint from "../../structure";

// Simulate the delete-merchant-location API
export async function fakeDeleteMerchantLocation(data) {
  // Define the expected data structure for this endpoint (none required in the body, but ID is in the path)
  const dataStructure = {
    id: { type: "number", required: true }, // The ID will typically be extracted from the path in a real scenario
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "Invalid or missing ID parameter." }, status: 400 },
    { error: { error: "Location not found." }, status: 404 },
    { error: { error: "An error occurred on our end." }, status: 500 },
  ];

  // Define the success output (204 No Content typically has no body, but let's return a confirmation message)
  const successOutput = {
    message: "The location was successfully deleted.",
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput,
    { successStatusCode: 204 } // Override the default status code to 204 No Content
  );
}
