import { useState } from "react";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";


const useConfirmCodeDialog = ({ type, profile_pk, onSuccess }) => {
  const [credential, setCredential] = useState("");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const fetchFeedback = useFullFetchFeedback();

  const validateFields = [
    {
      field: "otp",
      type: "content",
      required: true,
      customMessage: "OTP is required.",
    },
    {
      field: "credential",
      type: "content",
      required: type === "password",
      customMessage: "Current Username is required.",
    },
    {
      field: "credential",
      type: "content",
      required: type === "username",
    },
  ];

  const handleSubmit = () => {
    fetchFeedback({
      endpoint: "merchant/reset-check/",
      loaderType: 'dialogueLoader',
      validationRules: validateFields,
      auth: false, 
      requestType: "POST",
      data: { otp, profile_pk, type, credential },
      loaderMessage: "Verifying OTP...",
      onSuccess: (response) => onSuccess(response),
      setError: setErrors,
    });
  };

  return {
    credential,
    otp,
    errors,
    setCredential,
    setOtp,
    handleSubmit,
  };
};

export default useConfirmCodeDialog;
