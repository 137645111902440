import React, { useEffect, useState } from "react";
import {
  Stack, 
} from "@mui/material";
import submittedImage from "../../../../images/done.png";
import PageLayout from "../../../../components/pageStructures/authPages/master";
import DocumentTypeField from "./components/documentTypeField";
import UploadField from "./components/uploadField";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../features/alerts/alertSlice";



const DocumentUploadField = ({ documentDetails, setDocumentList, setSelectedDocument }) => {
 
  const [type, setType] = useState("");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();


  useEffect(() => {
    if (documentDetails && documentDetails.document && documentDetails.document.errors) {
      const errorMessage = `Uploaded document was invalid. ${documentDetails.document.errors.join(', ')}`;
      dispatch(showAlert({ type: 'error', message: errorMessage }));
    }
  }, [documentDetails, dispatch]);

  const handleDocumentUpload = () => {
    setDocumentList(prev =>
      prev.map((doc, index) =>
        index === documentDetails.index
          ? { ...doc, type, file, isCompleted: true }
          : doc
      )
    );
    setSelectedDocument(null); // Reset selected document if needed
  };



  return (
    <PageLayout
    sideImageUrl={submittedImage}
    handleSubmit={handleDocumentUpload}
    title={documentDetails.document.name}
    description={documentDetails.document.headerText}
    currentStep={4}
    onBack={() => {setSelectedDocument(null);}}
    backArrow={true}
  >
      <Stack spacing={2}>
      <DocumentTypeField
          selectedType={type}
          handleDocumentTypeChange={(e) => {setType(e.target.value);}}
          document={documentDetails.document}
          
        />
        <UploadField
          onFileSelect={ (selectedFile) => {setFile(selectedFile);}}
        />

      </Stack>

      </PageLayout>
  );
};

export default DocumentUploadField;
