import { useMediaQuery, useTheme } from "@mui/material";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../../features/user/userSelectors";

export const useTopBar = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const currentUser = useSelector(selectCurrentUser);

  return {
    isMdUp,
    currentUser,
  };
};
