// useUserOptions.js
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const useUserOptions = () => {
  const [credentialType, setCredentialType] = useState(null);
  const navigate = useNavigate();

  const handleSignIn = (event) => {
    event.preventDefault();
    // Add sign-in logic if needed
  };

  const handleSignUp = () => {
    navigate("/email");
  };

  const handleResetPassword = (event) => {
    event.preventDefault();
    setCredentialType("password");
  };

  const handleResetUsername = (event) => {
    event.preventDefault();
    setCredentialType("username");
  };

  const handleCloseDialog = () => {
    setCredentialType(null);
  };

  return {
    credentialType,
    handleSignIn,
    handleSignUp,
    handleResetPassword,
    handleResetUsername,
    handleCloseDialog,
  };
};

export default useUserOptions;
