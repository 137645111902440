import { Box } from "@mui/material";
import React, { useState } from "react";
import ConfirmDialog from "../../../components/molecules/dialogues/confirm";
import CustomInputDialog from "../../../components/molecules/dialogues/customInput";
import GeneralInput from "../../../components/atoms/inputs/GeneralInput";
import { handleInputChange } from "../../../functions/general/setData";

const UpdateConfirmationDialog = ({ open, onClose, onSubmit }) => {
  const [accountInfo, setAccountInfo] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [formVisible, setFormVisible] = useState(false);

  return (
    <>
      <ConfirmDialog
        open={open && !formVisible}
        onClose={onClose}
        onConfirm={() => setFormVisible(true)}
        title="Change Credentials?"
        content="Are you sure you want to change the username and password? All registers will need new credentials. Employees will also need to be updated."
      />

      {formVisible && (
        <CustomInputDialog
          open={open}
          onClose={onClose}
          onSubmit={() =>
            onSubmit(accountInfo.username, accountInfo.password, setError)
          }
          title="Update Register Control"
          subTitle="Change the username and password for all your store clavaa registers. We will generate a new refund pin too."
        >
          <Box sx={{ display: "grid", gap: 2, width: "85%", margin: "auto" }}>
            <GeneralInput
              type={"text"}
              label="Username"
              name={"username"}
              value={accountInfo.username}
              onChange={(e) => handleInputChange(e, setAccountInfo)}
              error={!!error.username}
              errorMessageAndhelp={error.username || ""}
            />
            <GeneralInput
              type={"password"}
              label="Password"
              name={"password"}
              value={accountInfo.password}
              onChange={(e) => handleInputChange(e, setAccountInfo)}
              error={!!error.password}
              errorMessageAndhelp={error.password || ""}
            />
          </Box>
        </CustomInputDialog>
      )}
    </>
  );
};

export default UpdateConfirmationDialog;
