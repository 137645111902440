import React, { useState, useEffect } from "react";
import SuccessDialog from "../../../../components/molecules/dialogues/success";
import ConfirmCodeDialog from "./ConfirmCodeDialog";
import RequestCodeDialog from "./RequestCodeDialog";
import UpdateCredentialDialog from "./UpdateCredentialDialog";

const CredentialManager = ({ type, open, handleClose }) => {
  const [currentStep, setCurrentStep] = useState("requestCode");

  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [profilePk, setProfilePk] = useState();
  console.log(type);

  useEffect(() => {
    if (!open) {
      // Reset the workflow if the dialog is closed
      setProfilePk("");
      setCurrentStep("requestCode");
      setShowSuccessDialog(false);
    }
  }, [open]);

  const handleEmailSuccess = (ProfilePk) => {
    setProfilePk(ProfilePk);

    setCurrentStep("submitCode");
  };

  const handleCodeSuccess = () => {
    setCurrentStep("updateCredential");
  };

  const handleCredentialUpdateSuccess = () => {
    setCurrentStep("success");
    setShowSuccessDialog(true);
  };

  const handleSuccessDialogClose = () => {
    setShowSuccessDialog(false);
    handleClose(); // Ultimately close everything and reset
  };

  const renderCurrentDialog = () => {
    switch (currentStep) {
      case "requestCode":
        return (
          <RequestCodeDialog
            open={open && currentStep === "requestCode"}
            onSuccess={handleEmailSuccess}
            handleClose={handleClose}
          />
        );
      case "submitCode":
        return (
          <ConfirmCodeDialog
            type={type}
            open={open && currentStep === "submitCode"}
            onSuccess={handleCodeSuccess}
            handleClose={handleClose}
            profile_pk={profilePk}
          />
        );
      case "updateCredential":
        return (
          <UpdateCredentialDialog
            type={type}
            open={open && currentStep === "updateCredential"}
            onSuccess={handleCredentialUpdateSuccess}
            handleClose={handleClose}
            profile_pk={profilePk}
          />
        );
      case "success":
        return (
          <SuccessDialog
            open={showSuccessDialog}
            message={`${
              type.charAt(0).toUpperCase() + type.slice(1)
            } has been updated successfully!`}
            onClose={handleSuccessDialogClose}
          />
        );
      default:
        return null;
    }
  };

  return <>{renderCurrentDialog()}</>;
};

export default CredentialManager;
