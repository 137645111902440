import React from 'react';
import {
    Paper,
    Box,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    Skeleton,
} from '@mui/material';

const CustomTable = ({
    title,
    columns,
    data,
    loading = false,
    rowsPerPage = 10,
    totalRows = data.length,
    page = 0,
    handlePageChange = () => {},
    showPagination = false,
}) => {
    return (
        <TableContainer component={Paper} elevation={3} sx={{ borderRadius: '15px', overflow: 'hidden' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
                <Typography variant="h6" component="div">
                    {title}
                </Typography>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map((column, index) => (
                            <TableCell key={index} align="center">{column.title}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {loading
                        ? Array.from({ length: rowsPerPage }).map((_, index) => (
                            <TableRow key={index}>
                                <TableCell colSpan={columns.length} align="center">
                                    <Skeleton animation="wave" height={40} variant="text" width="100%" />
                                </TableCell>
                            </TableRow>
                          ))
                        : data.length > 0 ? (
                            data.map((row, index) => (
                                <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                                    {columns.map((column, columnIndex) => (
                                        <TableCell key={columnIndex} align="center">{row[column.key]}</TableCell>
                                    ))}
                                </TableRow>
                            ))
                          ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} align="center">No data found.</TableCell>
                            </TableRow>
                          )
                    }
                </TableBody>
            </Table>
            {showPagination && (
                <TablePagination
                    component="div"
                    count={totalRows}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={(e, newPage) =>  handlePageChange(newPage)}
                    rowsPerPageOptions={[rowsPerPage]} // Fixed rows per page
                />
            )}
        </TableContainer>
    );
};

export default CustomTable;
