import { useState } from "react";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";


const useUpdateCredentialDialog = ({ type, profile_pk, onSuccess }) => {
  const [credential, setCredential] = useState("");
  const [error, setError] = useState("");
  const fetchFeedback = useFullFetchFeedback();

  const validateFields = [
    {
      field: "credential",
      type: "content",
      required: true,
    },
  ];

  const handleSubmit = () => {
    fetchFeedback({
      endpoint: "merchant/reset-credential/",
      validationRules: validateFields,
      auth: false, 
      loaderType: 'dialogueLoader',
      requestType: "POST",
      data: { profile_pk, type, credential },
      loaderMessage: "Updating registration...",
      onSuccess: (response) => onSuccess(response),
      setError,
    });
  };

  return {
    credential,
    error,
    setCredential,
    handleSubmit,
    renderTextFieldLabel: () => (type === "username" ? "New Username" : "New Password"),
    renderTextFieldType: () => (type === "password" ? "password" : "text"),
  };
};

export default useUpdateCredentialDialog;
