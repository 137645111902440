export function checkForErrors(inputData, errorArray) {
  for (const key in inputData) {
    const value = inputData[key];

    // Check if the value is a string and matches the special error trigger values
    if (typeof value === "string") {
      // Check for specific numeric strings that should trigger errors
      const numericValue = parseInt(value, 10);
      if ([11, 12, 13].includes(numericValue)) {
        return errorArray[numericValue - 11];
      }

      // Existing check for string containing "error"
      if (value.toLowerCase().includes("error")) {
        const errorIndex = parseInt(value.match(/\d+/), 10) - 1;
        if (errorIndex >= 0 && errorIndex < errorArray.length) {
          return errorArray[errorIndex];
        }
      }
    }

    // Check if the value is a number and matches the special error trigger values
    if (typeof value === "number" && [11, 12, 13].includes(value)) {
      return errorArray[value - 11];
    }

    // Recursively check nested objects
    if (typeof value === "object" && value !== null) {
      const nestedError = checkForErrors(value, errorArray);
      if (nestedError) return nestedError;
    }
  }
  return null;
}
