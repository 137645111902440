import CashbackUpdateModalUI from "../../ui/molecules/cashbackUpdateModalUI";
import useCashbackUpdateModalUI from "../../functional/molecules/cashbackUpdateModalUI"
const CashbackUpdateModalContainer = ({ open, onClose, currentCashback }) => {
  const { handleCashbackChange, handleUpdateCashbackSubmit, handleClose, cashbackValue } = useCashbackUpdateModalUI({ onClose });
  return (
    <CashbackUpdateModalUI
      handleCashbackChange={handleCashbackChange}
      cashbackValue={cashbackValue}
      handleUpdateCashbackSubmit={handleUpdateCashbackSubmit}
      handleClose={handleClose}
      open={open}
      onClose={onClose}
      currentCashback={currentCashback}
    />
  );
};

export default CashbackUpdateModalContainer;
