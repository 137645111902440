import React from "react";
import {
  Dialog,
  CircularProgress,
  DialogContent,
  Typography,
} from "@mui/material";

const LoaderDialog = ({ open, message }) => {
  return (
    <Dialog open={open} aria-labelledby="loading-dialog-title">
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "24px",
        }}
      >
        <CircularProgress />
        {message && (
          <Typography style={{ marginTop: "20px" }}>{message}</Typography>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LoaderDialog;
