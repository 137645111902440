import { Button, Typography, Box } from "@mui/material";
import GeneralButton from "./../../../../components/atoms/buttons/GeneralButton";

const LogoutPanelUI = ({handleSignOut}) => {
    return(
        <Box
        sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Adjust as needed
            mt: 4,
        }}
        >
        <Typography variant="body1" sx={{ mb: 2 }}>
            Are you sure you want to log out?
        </Typography>
        <GeneralButton label="Log Out" onClick={handleSignOut} type="contained" />
        </Box>
    );
}

export default LogoutPanelUI;