import masterFakeEndpoint from "../../structure";

// Utility function to determine if data should be included (90% chance)
function shouldIncludeData() {
  return Math.random() < 0.9;
}

function getRandomStatus() {
  const random = Math.random();
  
  if (random < 0.88) {
    return "complete"; // 88% chance of returning "complete"
  } else if (random < 0.91) {
    return "personalInfo"; // 3% chance of returning "personalInfo"
  } else if (random < 0.93) {
    return "businessInfo"; // 2% chance of returning "businessInfo"
  } else {
    return "verify"; // 7% chance of returning "verify"
  }
}

// Function to generate random transaction data
function getRandomTransaction(id) {
  return {
    id: id,
    name: `Transaction ${id}`,
    cashbackEarned: 10.0,
    cashbackAmount: 100.0,
    processing_status: "successful",
    register: 1234 + id,
    amount: 150.0,
    date: "2024-08-22",
  };
}

// Function to generate transactions dynamically
function generateTransactions() {
  const transactionCount = Math.random() < 0.8 ? 10 : Math.floor(Math.random() * 9) + 1; // 80% chance to generate 10 transactions
  const transactions = [];

  for (let i = 1; i <= transactionCount; i++) {
    transactions.push(getRandomTransaction(i));
  }

  return {
    transactions,
    transaction_count: transactionCount === 10 ? Math.floor(Math.random() * 21) + 30 : transactionCount,
  };
}

// Simulate the Dashboard API
export async function fakeMerchantDashboardData(data) {
  // Define the expected data structure for this endpoint (no input required, but can validate token if needed)
  const dataStructure = {}; // No required input fields, but you could add token verification logic here

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Generate transactions and transaction count
  const { transactions, transaction_count } = shouldIncludeData() ? generateTransactions() : { transactions: [], transaction_count: 0 };

  // Define the success output with conditional data inclusion
  const successOutput = {
    merchant_profile: shouldIncludeData()
      ? { full_name: "John Doe" }
      : {}, // Empty object if no data
    status: getRandomStatus(),
    transactions: transactions,
    transaction_count: transaction_count,
    cashback_offer: shouldIncludeData() ? 5.5 : 0,
    cashback_history: shouldIncludeData()
      ? [
          {
            start_date: "2024-08-01",
            end_date: "2024-08-15",
            amount: 25.0,
          },
          {
            start_date: "2024-07-01",
            end_date: "2024-07-15",
            amount: 30.0,
          },
          {
            start_date: "2024-06-01",
            end_date: "2024-06-15",
            amount: 40.0,
          },
        ]
      : [], // Empty array if no data
    account: shouldIncludeData()
      ? {
          accountNumber: "123456789",
          accountName: "Merchant Bank",
          balance: "$5,000.00",
          lastChecked: "2024-08-22 10:00:00",
          logo: "https://via.placeholder.com/150",
        }
      : {}, // Empty object if no data
    pending_balance: shouldIncludeData() ? 500.0 : 0,
    dashboard_data: shouldIncludeData()
      ? {
          transactions: "10,000.00",
          cashbackEarned: "500.00",
          cashbackRedeemed: "300.00",
          totalUsers: "100",
        }
      : {
          transactions: "0",
          cashbackEarned: "0",
          cashbackRedeemed: "0",
          totalUsers: "0",
        }, 
    existing_non_admin_exists: shouldIncludeData() ? true : false,
    register_data: shouldIncludeData()
      ? {
          username: "merchantUser",
          register_pin: 1234,
          register_count: 2,
        }
      : {}, // Empty object if no data
    store_locations: shouldIncludeData()
      ? [
          {
            id: 1,
            name: "Main Store",
            address: "123 Main St, Anytown, USA",
            storePhoto: "https://via.placeholder.com/650",
            description: "The main retail location.",
            type: "Retail",
            number: "+1 555-1234",
            website: "https://mainstore.com",
            email: "contact@mainstore.com",
            status: "Active",
          },
          {
            id: 2,
            name: "Second Store",
            address: "456 Secondary St, Othertown, USA",
            storePhoto: "https://via.placeholder.com/650",
            description: "A secondary retail location.",
            type: "Retail",
            number: "+1 555-5678",
            website: "https://secondstore.com",
            email: "contact@secondstore.com",
            status: "Active",
          },
          {
            id: 3,
            name: "Online Store",
            address: "https://onlinestore.com",
            storePhoto: "https://via.placeholder.com/650",
            description: "The online store.",
            type: "Online",
            number: "+1 555-8765",
            website: "https://onlinestore.com",
            email: "support@onlinestore.com",
            status: "Active",
          },
        ]
      : [], // Empty array if no data
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(dataStructure, data, errorArray, successOutput);
}
