import React, { useEffect, useState } from "react";
import DocumentStack from "./components/documentStack";
import DocumentUploadField from "./documentUploadField";
import PageLayout from "../../../../components/pageStructures/authPages/master";
import submittedImage from "../../../../images/done.png";
import useFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../../features/alerts/alertSlice";

const DocumentStatusList = () => {
  const location = useLocation();
  const { documents } = location.state || {};
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentList, setDocumentList] = useState(documents);
  const fetchFeedback = useFetchFeedback();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isAllDocumentsUploaded = documentList.every((doc) => doc.isCompleted);
  // check to make sure the data is passed otherwise send back
  useEffect(() => {
    if (!documents) {
      navigate("/status");
    }
  }, [documents, navigate]);

  useEffect(() => {
    const invalidDocuments = documents.filter(
      (doc) => doc.errors && doc.errors.length > 0
    );
    if (invalidDocuments.length > 0) {
      dispatch(
        showAlert({
          type: "error",
          message:
            "Some of your documents were invalid. We need you to update them for verification.",
        })
      );
    }
  }, []);

  const onDocumentUpload = async (event) => {
    try {

     
  
      await fetchFeedback({
        endpoint: "merchant/document/upload/",
        data:  documentList , 
        loaderMessage: "Uploading documents...",
        requestType: "POST",
        onSuccess: (responseData) => {
          navigate("/status");
        },
        onError: (error) => {
          console.error("Upload error:", error);
        },
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  

  if (selectedDocument) {
    return (
      <DocumentUploadField
        documentDetails={selectedDocument}
        setDocumentList={setDocumentList}
        setSelectedDocument={setSelectedDocument}
      />
    );
  }

  return (
    <PageLayout
      sideImageUrl={submittedImage}
      title={"Required Documents"}
      description={
        "We just need a couple documents to verify some of your information."
      }
      currentStep={4}
      handleSubmit={onDocumentUpload}
      disabled={!isAllDocumentsUploaded}
    >
      <DocumentStack
        data={documentList}
        onItemClick={(document, index) =>
          setSelectedDocument({ document, index })
        }
      />
    </PageLayout>
  );
};

export default DocumentStatusList;
