import React from "react";
import useInitializePlaid from "./plaid";
import { Typography } from "@mui/material";
import ConnectingBankAccount from "./accountLoading";
import GeneralButton from "../../../components/atoms/buttons/GeneralButton";
import CustomCard from "../components/card";
import InfoIconButton from "../../../components/molecules/dialogues/info";
import { selectBankData } from "../../../features/banking/bankSelectors";
import { useSelector } from "react-redux";

const BankTab = () => {
  const { open, ready, openModal } = useInitializePlaid();
  const bankData = useSelector(selectBankData);

  const handleClick = () => {
    localStorage.removeItem("plaidLinkToken");
    localStorage.removeItem("plaidLinkTokenExpiry");
    ready && open();
  };

  return (
    <>
      <CustomCard>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          Bank Balance
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "500" }}>
          ********{bankData.accountNumber}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Account details
        </Typography>
        <img
          src={bankData.logo}
          alt="Bank Logo"
          style={{ width: 50, borderRadius: "50%" }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px 0",
          }}
        >
          <Typography>
            <div>{bankData.accountName}</div>
            <div>Balance {bankData.balance}</div>
          </Typography>
        </div>
        <GeneralButton
          label="Switch Account"
          onClick={handleClick}
          type="outlined"
        />
        <InfoIconButton
          title={"Account Information"}
          info={
            "This section provides detailed information about your bank account, such as balance and transaction history. Ensure your account is always connected for up-to-date information."
          }
        />
      </CustomCard>
      <ConnectingBankAccount open={openModal} />
    </>
  );
};

export default BankTab;
