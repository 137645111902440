import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useDispatch } from "react-redux";
import { showDialog } from "../../../features/dialog/dialogSlice";

const InfoIconButton = ({ title, info }) => {
  const dispatch = useDispatch();


  const handleClickOpen = () => {
    dispatch(showDialog({ dialogType: 'info', message: info, title: title }));
    
  };


  return (
    <>
      <IconButton
        onClick={handleClickOpen}
        size="small"
        sx={{
          position: "absolute",
          top: 8,
          right: 8,
          color: "primary",
        }}
      >
        <HelpOutlineIcon />
      </IconButton>
      
    </>
  );
};

export default InfoIconButton;
