import React, { useEffect } from 'react';
import { Box, Typography, CircularProgress, Paper } from '@mui/material';
import submittedImage from "../../../images/done.png";
import PageLayout from '../../../components/pageStructures/authPages/master';
import handleNavigation from './functions/navigate';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { masterRequest } from '../../../functions/apiStructures/masterRequest';
import { showAlert } from "../../../features/alerts/alertSlice";
import { setStatus } from '../../../features/user/userSlice';



const VerificationPage = () => {



 const navigate = useNavigate();
 const dispatch = useDispatch();

  
 useEffect(() => {
  async function fetchVerificationStatus() {
      try {
        const token = localStorage.getItem("token");
          const data = await masterRequest(`merchant/verification-status/`, "GET", null,  token);
          if (data?.error){
            dispatch(showAlert({ type: "warning", message: "We are encountering an error. Try again later or contact customer service." }));

          } 

          else{
            handleNavigation(data, navigate, (status) => dispatch(setStatus(status)));}

      } catch (error) {
          console.error('Error fetching verification status:', error);
      }
  }

  fetchVerificationStatus();
}, []);

  return (
    <PageLayout
      sideImageUrl={submittedImage}
      title={"Account Verification"}
      description={"Ensuring the safety and security of our platform is our top priority."}
      currentStep={5}
    >
      <Box sx={{ maxWidth: 600, mx: 'auto', textAlign: 'center', p: 4, mt: 4 }}>
        <Paper elevation={3} sx={{ p: 4 }}>
          <CircularProgress sx={{ mb: 2 }} />
          <Typography variant="h6" sx={{ mb: 2 }}>
            Verifying Your Account
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
            We are currently verifying your account information with Dwolla, our trusted payments and identity provider. This process is essential to ensure the security and integrity of our platform.
          </Typography>
          <Typography variant="body2" sx={{ mb: 2, color: 'gray' }}>
            Please do not close this page or navigate away while we complete the verification. This may take a few moments.
          </Typography>
        </Paper>
      </Box>
    </PageLayout>
  );
};

export default VerificationPage;
