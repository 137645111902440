import React from "react";
import ContactSupportPanelUI from "../../ui/molecules/contactSupportPanelUI" 
import {
    CardContent,
  } from "@mui/material";

const ContactSupportPanelContainer = () => {
    return (
        <CardContent>
            <ContactSupportPanelUI />
        </CardContent>
    )
}

export default ContactSupportPanelContainer;