import React, { useState } from "react";
import accountImage from "../../../images/account.png";
import { useLocation, useNavigate } from "react-router-dom";

import useFullFetchFeedback from "../../../functions/apiFetchers/fullPageLoaders";
import GeneralInput from "../../../components/atoms/inputs/GeneralInput";

import { Box } from "@mui/material";
import PageLayout from "../../../components/pageStructures/authPages/master";
import { handleInputChange } from "../../../functions/general/setData";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../features/alerts/alertSlice";
import { setStatus } from "../../../features/user/userSlice";

const AccountInfoPage = ({ onNext }) => {
  const [accountInfo, setAccountInfo] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const profile_pk = location.state?.profile_pk;
  const email = location.state?.email;
  const fetchFeedback = useFullFetchFeedback();

  const handleSubmit = async (event) => {
    const errorValidation = [{ field: 'username', type: 'content', required: true}, { field: 'password', type: 'password', required: true}, { field: 'profile_pk', type: 'number', required: true, customMessage: 'Please reverify email'}]
   
    await fetchFeedback({
      endpoint: "merchant/account_info/",
      requestType: "POST",
      validationRules: errorValidation,
      data: { ...accountInfo, profile_pk, email },
      loaderMessage: "Creating Account...",
      auth: false, 
   

      onSuccess: (responseData) => {
        localStorage.setItem("token", responseData.token);
        dispatch(setStatus('personalInfo'))
        navigate("/personal-info");
      },
      onError: (error) => {
       
        if (error.profile_pk) {
          navigate("/email");
          dispatch(showAlert({ type: "error", message: "please reverify your email" }));
        }
        else{
        setError(error);}
      },
    });
  };

  return (
    <PageLayout
      sideImageUrl={accountImage}
      handleSubmit={handleSubmit}
      title={"Account Information"}
      description={"Enter your username and password to proceed."}
      currentStep={3}
      navigate={navigate}
    >
      <Box sx={{ display: "grid", gap: 2 }}>
        <GeneralInput
          type={"text"}
          label="Username"
          name={"username"}
          value={accountInfo.username}
          onChange={(e) => handleInputChange(e, setAccountInfo)}
          error={!!error.username}
          errorMessageAndhelp={error.username || ""}
        />
        <GeneralInput
          type={"password"}
          label="Password"
          name={"password"}
          value={accountInfo.password}
          onChange={(e) => handleInputChange(e, setAccountInfo)}
          error={!!error.password}
          errorMessageAndhelp={error.password || ""}
        />
      </Box>
    </PageLayout>
  );
};

export default AccountInfoPage;
