import React from 'react';
import { useSideNav } from '../functional/useSideNav ';
import SideNavComponent from '../ui/SideNavComponent';




const SideNavContainer = ({ drawerOpen, toggleDrawer }) => {
  const { onTabClick } = useSideNav();

  return (
    <SideNavComponent
      drawerOpen={drawerOpen}
      onTabClick={route => {
        onTabClick(route);
        if (!drawerOpen) {
          toggleDrawer(); // Optionally close the drawer on tab click
        }
      }}
    />
  );
};

export default SideNavContainer;
