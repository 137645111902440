import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
  DialogActions,
} from "@mui/material";
import GeneralButton from "../../../../components/atoms/buttons/GeneralButton";

function CashbackHistoryModalUI({ open, onClose, cashbackHistory }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cashback-history-dialog-title"
      aria-describedby="cashback-history-description"
      maxWidth="sm" // Adjust the size as needed
      fullWidth
    >
      <DialogTitle id="cashback-history-dialog-title">
        Cashback History
      </DialogTitle>
      <DialogContent>
        <List
          sx={{
            width: "100%",
            // bgcolor: 'background.paper', // This is not needed since DialogContent has a background
            overflow: "auto",
            maxHeight: "60vh", // Adjust based on your needs
          }}
        >
          {cashbackHistory.map((offer, index) => (
            <React.Fragment key={index}>
              <ListItem alignItems="flex-start">
                <ListItemText
                  primary={`${offer.amount}% back`}
                  secondary={
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {`Start: ${offer.start_date} - End: ${offer.end_date}`}
                    </Typography>
                  }
                />
              </ListItem>
              {index < cashbackHistory.length - 1 && (
                <Divider variant="inset" component="li" />
              )}
            </React.Fragment>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <GeneralButton label="Close" onClick={onClose} type="PlainText" />
      </DialogActions>
    </Dialog>
  );
}

export default CashbackHistoryModalUI;
