import React from "react";
import { Stepper, Step, StepLabel, StepConnector } from "@mui/material";
import { styled } from "@mui/material/styles";
import stepConnectorClasses from "@mui/material/StepConnector/stepConnectorClasses"; // Import stepConnectorClasses

// Customizing the StepConnector directly with inline styles
const customConnectorStyles = {
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#eaeaf0", // Default line color
  },
  [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
    borderColor: "#44C952", // Active connector line color
  },
  [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
    borderColor: "#44C952", // Completed connector line color
  },
};

const CustomConnector = styled(StepConnector)(customConnectorStyles);

const StepIndicator = ({ currentStep }) => {
  const stepLabels = [
    "Enter Email & Verify",
    "Account Info",
    "Personal Info",
    "Business Info",
    "Done",
  ];

  // Function to customize the StepIcon color based on its state
  const stepIconColor = (index) => ({
    color: index < currentStep ? "#44C952" : "#eaeaf0", // Active and completed steps are green; others are default
  });

  return (
    <Stepper
      alternativeLabel
      activeStep={currentStep - 1}
      connector={<CustomConnector />}
      sx={{
        mt: 4, // Adds margin top of 2 spacing units from the theme
      }}
    >
      {stepLabels.map((label, index) => (
        <Step key={index} completed={index < currentStep - 1}>
          <StepLabel StepIconProps={{ style: stepIconColor(index) }}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepIndicator;
