
import { Box, Tab, Tabs, Typography, Paper } from '@mui/material';

const SettingBox = ({tabValue, handleChange}) => {
    return(
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="Settings tabs"
          centered // Center the tabs
          variant="fullWidth" // Use this variant to make tabs take up the full container width
        >
          <Tab label="Contact Support" value="1" />
          <Tab label="Account status" value="2" />
          <Tab label="Logout" value="3" />
        </Tabs>
      </Box>
    )
}

export default SettingBox