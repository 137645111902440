import masterFakeEndpoint from "../../structure";

// Simulate the update-cashback API
export async function fakeUpdateCashback(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    cashback: { type: "string", required: true },
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "Cashback amount cannot exceed 40%." }, status: 400 },
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    new_cashback: data.cashback,
    new_history: {
      start_date: new Date().toISOString().split('T')[0], // Current date as start_date
      end_date: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().split('T')[0], // Yesterday as end_date
      amount: (data.cashback - 0.5).toFixed(2), // Simulate previous cashback amount as 0.5 less than the new one
    },
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
