import masterFakeEndpoint from "../../structure";

// Simulate the send-merchant-email-otp API
export async function fakeSendMerchantResetEmailOTP(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    email: { type: "string", required: true },
  };

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end" }, status: 400 },
  ];

  // Determine the output, with a 30% chance of returning 'invalid_profile_pk'
  let successOutput;
  if (Math.random() < 0.3) {
    successOutput = {
      error: "invalid_profile_pk",
    };
  } else {
    successOutput = {
      profile_pk: Math.floor(Math.random() * 10000) + 1, // Simulate a profile primary key
    };
  }

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(dataStructure, data, errorArray, successOutput);
}
