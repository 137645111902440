import React, { useEffect, useState } from "react";
import { Checkbox, FormControlLabel, Grid, Box } from "@mui/material";

import PageLayout from "../../../../components/pageStructures/authPages/master";
import personalInfoImage from "../../../../images/personal.png";
import validatePersonalDetails from "./function/validate";
import DynamicForm from "../../../../components/atoms/inputs/dynamic";

const PersonalInfoForm = ({
  personalInfo,
  setOwners,
  controllerIndex,
  setControllerIndex,
  handleInputChange,
  setValue,
}) => {
  const [errors, setError] = useState("");

  const fieldConfig = [
    {
      type: "text",
      label: "First Name",
      name: "first_name",
      grid: { xs: 12, sm: 6 },
    },
    {
      type: "text",
      label: "Last Name",
      name: "last_name",
      grid: { xs: 12, sm: 6 },
    },
    { type: "text", label: "Title", name: "title", grid: { xs: 12, sm: 6 } },
    {
      type: "date",
      label: "Date of Birth",
      name: "date_of_birth",
      grid: { xs: 12, sm: 6 },
    },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
    {
      type: "ssn",
      label: personalInfo.isOwner ? "SSN" : "Last 4 of SSN",
      name: "ssn",
      grid: { xs: 12 },
      props: { fullSSN: personalInfo.isOwner },
    },
  ];

  // erases the controller as a user if he was set since they are resetting it.

  useEffect(() => {
    if (controllerIndex) {
      setOwners((currentOwners) =>
        currentOwners.filter((owner) => owner.id !== controllerIndex)
      );
      setControllerIndex(null);
    }
  }, []);

  const handleSubmit = () => {
    const errors = validatePersonalDetails(personalInfo);
    if (Object.keys(errors).length !== 0) {

      setError(errors);
      return;
    }

    if (personalInfo.isOwner) {
      setOwners((prevState) => {
        const highestId =
          prevState.length > 0
            ? prevState.reduce(
                (maxId, owner) => Math.max(maxId, parseInt(owner.id, 10)),
                0
              )
            : 0;
        const newOwner = {
          ...personalInfo,
          id: highestId + 1,
        };

        setControllerIndex(highestId + 1);
        return [...prevState, newOwner];
      });
    }
    // This navigates to the next page
    setValue(1);
  };

  return (
    <PageLayout
      sideImageUrl={personalInfoImage}
      handleSubmit={handleSubmit}
      title={"Controllers Details"}
      description={"Please fill in the businesses controllers details."}
      currentStep={3}
    >
      <Box
        sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}
      >
        <DynamicForm
          fieldConfig={fieldConfig}
          formData={personalInfo}
          handleInputChange={handleInputChange}
          errors={errors}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={personalInfo.isOwner || false}
              onChange={handleInputChange}
              name="isOwner"
            />
          }
          label="Check here if the controller owns at least 25% of the entity."
        />
      </Box>
    </PageLayout>
  );
};

export default PersonalInfoForm;
