import masterFakeEndpoint from "../../structure";

// Simulate the transactions-list API
export async function fakeTransactionsList() {
  // Define the expected data structure for this endpoint
  const dataStructure = {};

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Utility function to generate a random ID in the thousands range
  function generateUniqueId() {
    return Math.floor(Math.random() * 9000) + 1000; // Generates a number between 1000 and 9999
  }

  // Define a simulated list of transactions (for demonstration purposes)
  const transactions = Array.from({ length: 50 }, () => ({
    id: generateUniqueId(),
    name: `User ${Math.floor(Math.random() * 100) + 1}`,
    cashbackEarned: (Math.random() * 10).toFixed(2),
    cashbackAmount: (Math.random() * 100).toFixed(2),
    processing_status: "Completed",
    register: `Register ${Math.ceil(Math.random() * 5)}`,
    amount: (Math.random() * 500).toFixed(2),
    date: new Date(new Date().setDate(new Date().getDate() - Math.floor(Math.random() * 50))).toISOString().split('T')[0],
  }));

  // Set default pagination details
  const page = 1;
  const page_size = 10;
  const start = (page - 1) * page_size;
  const end = start + page_size;
  const paginatedResults = transactions.slice(start, end);

  // Define the success output
  const successOutput = {
    count: transactions.length,
    next: end < transactions.length ? `?page=${page + 1}&page_size=${page_size}` : null,
    previous: start > 0 ? `?page=${page - 1}&page_size=${page_size}` : null,
    results: paginatedResults,
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    {}, // No data being sent, so pass an empty object
    errorArray,
    successOutput
  );
}
