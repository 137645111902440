import LogoutPanelUI from "../../ui/molecules/logoutPanelUI"; 
import LogoutPanel from './../../functional/molecules/logoutPanel';

const LogoutPanelContainer = () => {
    const {
        handleSignOut
    } = LogoutPanel();
    
    return(
        <LogoutPanelUI
            handleSignOut={handleSignOut}
        />
    );
}

export default LogoutPanelContainer;