import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TextField, InputAdornment, Button, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { showDialog } from '../../../../../features/dialog/dialogSlice';
const documentUploadInstructions = [
    "All 4 edges of the document should be visible.",
    "A dark/high contrast background should be used.",
    "At least 90% of the image should be the document.",
    "Should be at least 300dpi.",
    "Capture image from directly above the document.",
    "Make sure that the image is properly aligned, not rotated, tilted, or skewed.",
    "No flash to reduce glare.",
    "No black and white documents.",
    "No expired IDs."
  ];

const UploadField = ({  onFileSelect }) => {
  const dispatch = useDispatch();
  const [documentName, setDocumentName] = useState("No document uploaded");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentName(file.name);
  
      const reader = new FileReader();
      reader.onloadend = () => {
        if (onFileSelect) {
          onFileSelect(reader.result); // Pass the base64 encoded data URL
        }
      };
      reader.readAsDataURL(file);
    }
  };


  return (
    <TextField
      fullWidth
      variant="outlined"
      value={documentName}
      label="Document Name"
      InputProps={{
        disabled: true,
        endAdornment: (
          <InputAdornment position="end">
            <Button
              component="label"
              variant="contained"
              startIcon={<CloudUploadIcon />}
              sx={{ bgcolor: "primary.main", ":hover": { bgcolor: "primary.dark" } }}
            >
              Upload
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            <IconButton 
            onClick={() =>  dispatch(showDialog({ dialogType: 'info', message: documentUploadInstructions, title: "Upload Guidelines" }))} 
            sx={{ mr: "6px" }}>
              <HelpOutlineIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default UploadField;
