import React from 'react';
import Grid from '@mui/material/Grid';
import GeneralInput from './GeneralInput';


const DynamicForm = ({ fieldConfig, formData, handleInputChange, errors }) => {

  return (
    <Grid container spacing={2} sx={{ paddingTop: 1 }}>
      {fieldConfig.map((field) => {
        const isVisible = field.isVisible !== undefined ? field.isVisible : true;
        if (!isVisible) return null;

        return (
          <Grid item {...field.grid} key={field.name}>
            <GeneralInput
              type={field.type}
              label={field.label}
              name={field.name}
              value={formData[field.name] || ""}
              onChange={handleInputChange}
              error={!!errors[field.name]}
              errorMessageAndhelp={errors[field.name] || ""}
              multiline={field.multiline}
              rows={field.rows}
              options={field.options}
              {...field.props}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default DynamicForm;
