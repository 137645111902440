import masterFakeEndpoint from "../../structure";

// Simulate the Sign-In API
export async function fakeMerchantSignIn(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    username: { type: "string", required: true },
    password: { type: "string", required: true },
  };

  // Define potential errors
  const errorArray = [
    {
      error: {
        username: "",
        password: "Incorrect username and password",
      },
      status: 400,
    },
  ];

  // Define possible statuses
  const possibleStatuses = ["personalInfo", "businessInfo", "verify"];

  // Determine the status with a 50% chance of "certified"
  const status =
    Math.random() < 0.90
      ? "complete"
      : possibleStatuses[Math.floor(Math.random() * possibleStatuses.length)];

  // Define the success output
  const successOutput = {
    token: "your_authentication_token",
    status: status, // Selected status based on the logic above
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(dataStructure, data, errorArray, successOutput);
}
