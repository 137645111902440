// TransactionsTable.js
import React from "react";
import CustomTable from "../../../../components/molecules/tables/transactions";

const TransactionsTable = ({
  data,
  loading,
  rowsPerPage,
  totalRows,
  page,
  handlePageChange,
}) => {
  // Define columns as part of the UI component
  const columns = [
    { title: "Customer", key: "name" },
    { title: "Invoice ID", key: "id" },
    { title: "Date", key: "date" },
    { title: "Amount", key: "amount" },
    { title: "Cashback Earned", key: "cashbackEarned" },
    { title: "Cashback Redeemed", key: "cashbackAmount" },
    { title: "Status", key: "processing_status" },
    { title: "Register", key: "register" },
  ];

  return (
    <CustomTable
      title="Consumer Transactions"
      columns={columns}
      data={data} // Pass only the current page of transactions
      loading={loading}
      rowsPerPage={rowsPerPage}
      totalRows={totalRows}
      page={page}
      handlePageChange={handlePageChange}
      showPagination={true}
    />
  );
};

export default TransactionsTable;
