import CashbackHistoryModalUI from "../../ui/molecules/cashbackHistoryModalUI";

const CashbackHistoryModalContainer = ({ open, onClose, cashbackHistory }) => {
  return (
    <CashbackHistoryModalUI
      open={open}
      onClose={onClose}
      cashbackHistory={cashbackHistory}
    />
  );
};

export default CashbackHistoryModalContainer;
