import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import businessInfoImage from "../../../images/business.png";
import PageLayout from "../../../components/pageStructures/authPages/master";

import businessClassifications from "./classifications";
import { handleInputChange } from "../../../functions/general/setData";
import useFullFetchFeedback from "../../../functions/apiFetchers/fullPageLoaders";
import { useNavigate } from "react-router-dom";
import DynamicForm from "../../../components/atoms/inputs/dynamic";
import { setStatus } from "../../../features/user/userSlice";
import { useDispatch } from "react-redux";

const options = [
  { label: "Sole Proprietorship", value: "soleProprietorship" },
  { label: "Partnership", value: "partnership" },
  { label: "Limited Liability Company (LLC)", value: "llc" },
  { label: "Corporation", value: "corporation" },
];
const BusinessInfoPage = ({}) => {
  const [errors, setError] = useState("");
  const dispatch = useDispatch();
  const [businessInfo, setBusinessInfo] = useState({
    business_name: "",
    business_classification: "",
    business_classification_id: "",
    business_structure: "",
    ein: "",
    address: {},
  });
  const fetchFeedback = useFullFetchFeedback();
  const navigate = useNavigate();

  const fieldConfig = [
    {
      type: "text",
      label: "Business Name",
      name: "business_name",
      grid: { xs: 12 },
    },
    { type: "address", label: "Address", name: "address", grid: { xs: 12 } },
    {
      type: "autocomplete",
      label: "Business Classification",
      name: "business_classification_id",
      grid: { xs: 12, sm: 6 },
      options: businessClassifications,
    },
    {
      type: "autocomplete",
      label: "Business Structure",
      name: "business_structure",
      grid: { xs: 12, sm: 6 },
      options: options,
    },
    {
      type: "ein",
      label: "EIN",
      name: "ein",
      grid: { xs: 12 },
      isVisible:
        businessInfo.business_structure !== "" &&
        businessInfo.business_structure !== "soleProprietorship",
    },
  ];

  useEffect(() => {
    const selectedClassification = businessClassifications.find(
      (classification) =>
        classification.value === businessInfo.business_classification_id
    );

    setBusinessInfo((prevState) => ({
      ...prevState,
      business_classification: selectedClassification
        ? selectedClassification.label
        : "",
    }));
  }, [businessInfo.business_classification_id]);

  const handleSubmit = async (event) => {

    const validateBusinessDetails = [
      { field: 'business_name', type: 'content', required: true, customMessage: 'Business name is required.' },
      { field: 'business_classification_id', type: 'content', required: true, customMessage: 'Business classification is required.' },
      { field: 'business_structure', type: 'content', required: true, customMessage: 'Business structure is required.' },
      { field: 'address', type: 'address', required: true },
      { field: 'ein', type: 'ein', required: (formData) => formData.business_structure !== 'soleProprietorship'}]


    await fetchFeedback({
      endpoint: "merchant/business_info/",
      data: {...businessInfo },
      requestType: "POST",
      loaderMessage: "Submitting business details...",
      onSuccess: (responseData) => {
        dispatch(setStatus('verify'))
        navigate("/status");
      },

      validationRules: validateBusinessDetails,
      setError: setError,
    });
  };
  return (
    <PageLayout
      sideImageUrl={businessInfoImage}
      handleSubmit={handleSubmit}
      title={"Business Details"}
      description={"Please fill in the businesses details."}
      currentStep={4}
    >
      <Box
        sx={{ maxWidth: 500, width: "100%", mx: "auto", textAlign: "center" }}
      >
        <DynamicForm
          fieldConfig={fieldConfig}
          formData={businessInfo}
          handleInputChange={(e) => handleInputChange(e, setBusinessInfo)}
          errors={errors}
        />
      </Box>
    </PageLayout>
  );
};

export default BusinessInfoPage;
