import React from "react";
import { Typography, Button } from "@mui/material";
import useInitializePlaid from "./plaid";
import ConnectingBankAccount from "./accountLoading";
import InfoIconButton from "../../../components/molecules/dialogues/info";
import CustomCard from "../components/card";
import GeneralButton from "../../../components/atoms/buttons/GeneralButton";

const NoBankAccount = () => {
  const { open, ready, openModal } = useInitializePlaid();

  const handleClick = () => {
    localStorage.removeItem("plaidLinkToken");
    localStorage.removeItem("plaidLinkTokenExpiry");
    ready && open();
  };

  return (
    <>
      <CustomCard>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Bank Account
        </Typography>
        <Typography variant="h4" component="div" sx={{ mb: 2 }}>
          No account on file
        </Typography>
        <Typography variant="body1" sx={{ width: "50%", textAlign: "center" }}>
          You need to add a bank account to start processing payments.
        </Typography>
        <GeneralButton
          label="Add Account"
          onClick={handleClick}
          type="contained"
          size="medium"
          center={true}
        />

        <InfoIconButton
          title={"No account on file."}
          info={
            "Connect a bank account easily to activate your account and be able to receive payments directly into your bank account."
          }
        />
      </CustomCard>
      <ConnectingBankAccount open={openModal} />
    </>
  );
};

export default NoBankAccount;
