import React from "react";
import { useSelector, useDispatch } from "react-redux";

import SuccessDialog from "../components/molecules/dialogues/success";

import {
  selectDialogMessage,
  selectDialogTitle,
  selectIsDialogOpen,
} from "../features/dialog/dialogSelector";
import { hideDialog } from "../features/dialog/dialogSlice";
import Infopop from "../components/molecules/dialogues/infoNoIcon";

const DialogManager = () => {
  const dispatch = useDispatch();

  // Info Dialog State
  const isInfoOpen = useSelector((state) => selectIsDialogOpen(state, "info"));
  const infoMessage = useSelector((state) =>
    selectDialogMessage(state, "info")
  );
  const infoTitle = useSelector((state) => selectDialogTitle(state, "info"));
  const handleInfoClose = () => {
    dispatch(hideDialog("info"));
  };

  // Success Dialog State
  const isSuccessOpen = useSelector((state) =>
    selectIsDialogOpen(state, "success")
  );
  const successMessage = useSelector((state) =>
    selectDialogMessage(state, "success")
  );
  const handleSuccessClose = () => {
    dispatch(hideDialog("success"));
  };

  return (
    <>
      {isInfoOpen && (
        <Infopop
          open={isInfoOpen}
          onClose={handleInfoClose}
          title={infoTitle} // Now dynamic
          info={infoMessage}
        />
      )}
      {isSuccessOpen && (
        <SuccessDialog
          open={isSuccessOpen}
          message={successMessage}
          onClose={handleSuccessClose}
        />
      )}
    </>
  );
};

export default DialogManager;
