import React, { useState } from "react";
import submittedImage from "../../../../images/done.png";
import PageLayout from "../../../../components/pageStructures/authPages/master";
import InfoList from "./components/infoList";
import ChangeDataDialogue from "./components/dialogues/main";



const UpdateRetryInfo = ({ selectedRetryData, navigateBackToController }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState({});


  const handleItemClick = (details) => {
    setDialogData(details);

    setDialogData(details);
    setDialogOpen(true);
  };

  return (
    <>
      <PageLayout
        sideImageUrl={submittedImage}
       
        title={"Customer Retry Information"}
        description={
          "Recheck your information and make sure to add in your full social and other information."
        }
        onBack={navigateBackToController}
        backArrow={true}
        currentStep={5}
      >
        <InfoList 
          data={selectedRetryData.retryData} 
          onItemClick={handleItemClick} 
        />
      </PageLayout>
      {dialogOpen &&
      <ChangeDataDialogue
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        dialogData={dialogData}
        selectedRetryData={selectedRetryData}
      />}
    </>
  );
};

export default UpdateRetryInfo;
