import { useNavigate } from 'react-router-dom';

export const useAuthHelpers = () => {
  const navigate = useNavigate();

  const handleSignOut = () => {
    // Clear user-related and authentication data from localStorage
    localStorage.removeItem('token');
    localStorage.removeItem('business');
    localStorage.removeItem('personal');
    localStorage.removeItem('merchantStatus');

    // Navigate the user to the sign-in or sign-up page
    navigate('/email'); // Adjust the route as per your routing setup
  };

  return { handleSignOut };
};
