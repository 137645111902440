import masterFakeEndpoint from "../../structure";

// Simulate the upload documents for verification API
export async function fakeUploadDocumentsForVerification(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = [
    {
      file: { type: "string", required: true },
      serverName: { type: "string", required: true },
      id: { type: "integer", required: false },
      type: { type: "string", required: true },
    },
  ];

  // Define potential errors
  const errorArray = [
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    status: "documentPending",
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
