import { useSelector } from "react-redux";
import {
  selectCashbackHistory,
  selectCurrentCashback,
} from "../../../../features/cashback/cashbackSelectors";
import { useState } from "react";

const useCashback = () => {
  const currentCashback = useSelector(selectCurrentCashback);
  const cashbackHistory = useSelector(selectCashbackHistory);
  const [isUpdateModalOpen, setUpdateModalOpen] = useState(false);
  const [isHistoryModalOpen, setHistoryModalOpen] = useState(false);

  const handleOpenUpdateModal = () => setUpdateModalOpen(true);
  const handleCloseUpdateModal = () => setUpdateModalOpen(false);

  const handleOpenHistoryModal = () => setHistoryModalOpen(true);
  const handleCloseHistoryModal = () => setHistoryModalOpen(false);

  return {
    currentCashback,
    cashbackHistory,
    isUpdateModalOpen,
    isHistoryModalOpen,
    handleOpenUpdateModal,
    handleCloseUpdateModal,
    handleOpenHistoryModal,
    handleCloseHistoryModal,
  };
};

export default useCashback;
