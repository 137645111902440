import { createSlice } from "@reduxjs/toolkit";
import { updateDashboardMetrics } from "../dashboard/dashboardSlice";
import { setPendingBalance, setBankData } from "../banking/bankSlice";
import { setCurrentUser, setStatus } from "../user/userSlice";
import { updateRegisterData, updateMasterRegister } from "../register/registerSlice";
import { setTransactionCount, setTransactions } from "../transactions/transactionsSlice";
import { setStoreLocation } from "../stores/storesSlice";
import { setCashbackHistory, setCurrentCashback } from "../cashback/cashbackSlice";

// This function will be used to set the initial data
export const setInitialDashboardData = (data) => (dispatch) => {
  // Dispatch updates to various parts of the state:
  dispatch(setCurrentCashback(data.cashback_offer));
  dispatch(updateDashboardMetrics(data.dashboard_data));
  dispatch(setBankData(data.account));
  dispatch(setPendingBalance(data.pending_balance));
  dispatch(setCurrentUser(data.merchant_profile.full_name));
  dispatch(setStatus(data.status));
  dispatch(updateMasterRegister(data.existing_non_admin_exists));
  dispatch(updateRegisterData(data.register_data));
  dispatch(setTransactions(data.transactions));
  dispatch(setTransactionCount(data.transaction_count));
  dispatch(setStoreLocation(data.store_locations));
  dispatch(setCashbackHistory(data.cashback_history));
  
  // Update the state to reflect that the data has been fetched
  dispatch(initialDataSlice.actions.setHasFetched(true));
};

const initialDataSlice = createSlice({
  name: "initialData",
  initialState: {
    hasFetched: false,
  },
  reducers: {
    setHasFetched(state, action) {
      state.hasFetched = action.payload;
    },
  },
});

export const { setHasFetched } = initialDataSlice.actions;
export default initialDataSlice.reducer;
