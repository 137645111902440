import React from "react";
import useRequestCodeDialog from "../../functionality/molecules/useRequestCodeDialog";
import RequestCodeDialogUI from "../../ui/molecules/RequestCodeDialogUI";


const RequestCodeDialog = (props) => {
  const { email, error, setEmail, handleSubmit } = useRequestCodeDialog(props.onSuccess);

  return (
    <RequestCodeDialogUI
      {...props}
      email={email}
      error={error}
      setEmail={setEmail}
      handleSubmit={handleSubmit}
    />
  );
};

export default RequestCodeDialog;
