import React, { useContext, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmDialog from "../../components/molecules/dialogues/confirm";
import GeneralButton from "../../components/atoms/buttons/GeneralButton";

const LocationDetailsDialog = ({
  isOpen,
  onClose,
  location,
  handleConfirmDelete,
}) => {
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <DialogTitle>
          {location.name}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {location.storePhoto && (
          <img
            src={location.storePhoto}
            alt="Location"
            style={{
              maxWidth: "100%",
              height: "auto",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        )}
        <DialogContent>
          <Typography gutterBottom>Type: {location.type}</Typography>
          <Typography gutterBottom>Number: {location.number}</Typography>
          <Typography gutterBottom>Email: {location.email}</Typography>
          <Typography gutterBottom>Address: {location.address}</Typography>
          <Typography
            sx={{
              width: "90%",
              whiteSpace: "normal",
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            Description: {location.description}
          </Typography>
        </DialogContent>
        <DialogActions>
          <GeneralButton
            label="Delete"
            iconName={"DeleteIcon"}
            color="error"
            onClick={() => setIsConfirmDialogOpen(true)}
          />
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={isConfirmDialogOpen}
        onClose={() => setIsConfirmDialogOpen(false)}
        onConfirm={() => handleConfirmDelete(location.id)}
        title="Confirm Deletion"
        content="Are you sure you want to delete this location? This action cannot be undone."
      />
    </>
  );
};

export default LocationDetailsDialog;
