// useTransactions.js
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectTransactionCount, selectTransactions, selectTransactionsLoading } from "../../../../features/transactions/transactionsSelectors";
import { addTransactions, setLoading } from "../../../../features/transactions/transactionsSlice";
import { masterRequest } from "../../../../functions/apiStructures/masterRequest";

const useTransactions = () => {
  const dispatch = useDispatch();
  const transactions = useSelector(selectTransactions);
  const transactionCount = useSelector(selectTransactionCount);
  const loading = useSelector(selectTransactionsLoading);

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  useEffect(() => {
    const loadTransactions = async () => {
      dispatch(setLoading(true));
      console.log(page);

      // Fetch transactions
      const responseData = await masterRequest(
        `merchant/transactions/?page=${page + 1}&page_size=${rowsPerPage}`,
        "GET"
      );
      console.log(responseData);

      // Update Redux state with fetched transactions
      if (responseData.results) {
        dispatch(addTransactions(responseData.results));
        dispatch(setLoading(false));
      }
    };

    // Calculate the number of transactions required for the current page
    const transactionsNeeded = (page + 1) * rowsPerPage;

    // Only fetch more transactions if needed
    if (transactionsNeeded > transactions.length && transactions.length < transactionCount) {
      loadTransactions();
    }
  }, [dispatch, page, rowsPerPage, transactions.length, transactionCount]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return {
    transactions,
    transactionCount,
    loading,
    page,
    rowsPerPage,
    handlePageChange,
  };
};

export default useTransactions;
