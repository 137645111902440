import React from "react";
import useUpdateCredentialDialog from "../../functionality/molecules/useUpdateCredentialDialog";
import UpdateCredentialDialogUI from "../../ui/molecules/UpdateCredentialDialogUI";


const UpdateCredentialDialog = (props) => {
  const {
    credential,
    error,
    setCredential,
    handleSubmit,
    renderTextFieldLabel,
    renderTextFieldType,
  } = useUpdateCredentialDialog(props);

  return (
    <UpdateCredentialDialogUI
      {...props}
      credential={credential}
      error={error}
      setCredential={setCredential}
      handleSubmit={handleSubmit}
      renderTextFieldLabel={renderTextFieldLabel}
      renderTextFieldType={renderTextFieldType}
    />
  );
};

export default UpdateCredentialDialog;
