import { useState } from "react";
import { useDispatch } from "react-redux";
import useFullFetchFeedback from "../../../../functions/apiFetchers/fullPageLoaders";
import { addCashbackHistory, setCurrentCashback } from "../../../../features/cashback/cashbackSlice";

const useCashbackUpdateModalUI = ({ onClose }) => {
  const [cashbackValue, setCashbackValue] = useState("");
  const dispatch = useDispatch();
  const fetchFeedback = useFullFetchFeedback();

  const handleCashbackChange = (event) => {
    setCashbackValue(event.target.value);
  };

  const handleUpdateCashbackSubmit = async () => {
    await fetchFeedback({
      endpoint: `merchant/update-cashback/`,
      loaderType: "dialogueLoader",
      data: { cashback: cashbackValue },
      requestType: "POST",
      loaderMessage: "Updating cashback offer...",
      successMessage: "cashback offer updated successfully!",
      onSuccess: (responseData) => {
        console.log(responseData);
        dispatch(setCurrentCashback(responseData.new_cashback));
        dispatch(addCashbackHistory(responseData.new_history));
        setCashbackValue("");
        onClose();
      },
      onError: (error) => {
        setCashbackValue("");
        onClose();
      },
    });
  };

  const handleClose = () => {
    onClose();
    setCashbackValue(""); // Reset input on close as well
  };
  return {
    cashbackValue,
    handleCashbackChange,
    handleUpdateCashbackSubmit,
    handleClose,
  };
};

export default useCashbackUpdateModalUI;