import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  transactions: '0',
  cashbackEarned: '0',
  cashbackRedeemed: '0',
  totalUsers: '0',
  duration: 30,
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    updateDashboardMetrics: (state, action) => {
      console.log(action.payload);
      state.transactions = action.payload.transactions;
      state.cashbackEarned = action.payload.cashbackEarned;
      state.cashbackRedeemed = action.payload.cashbackRedeemed;
      state.totalUsers = action.payload.totalUsers;
    },
    setDuration: (state, action) => {
      state.duration = action.payload;
    },
  },
});

export const { updateDashboardMetrics, setDuration } = dashboardSlice.actions;
export default dashboardSlice.reducer;
