import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  masterRegister: true,
  registerData: {
    username: "",
    registerPin: "",
    registerCount: "",
  },
  currentRegister: 1,
  isLoading: false,
  error: null,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    updateRegisterData: (state, action) => {
      state.registerData = { ...state.registerData, ...action.payload };
    },
    updateMasterRegister: (state, action) => {
      state.masterRegister = action.payload;
    },
    setCurrentRegister: (state, action) => {
      state.currentRegister = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  updateRegisterData,
  updateMasterRegister,
  setCurrentRegister,
  setLoading,
  setError,
} = registerSlice.actions;

export default registerSlice.reducer;
