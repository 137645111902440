import React from "react";
import { TextField } from "@mui/material";
import CustomInputDialog from "../../../../components/molecules/dialogues/customInput";

function CashbackUpdateModalUI({ open, currentCashback, handleCashbackChange, handleUpdateCashbackSubmit, handleClose, cashbackValue }) {

  return (
    <CustomInputDialog
      open={open}
      onClose={handleClose}
      onSubmit={handleUpdateCashbackSubmit}
      title={`Current Offer: ${currentCashback}%`} // Note the template literal here
      subTitle="Update Cashback Offer"
    >
      <TextField
        fullWidth
        type="number"
        label="New Cashback Percentage"
        variant="outlined"
        value={cashbackValue}
        onChange={handleCashbackChange}
        InputProps={{
          endAdornment: "%",
          inputProps: {
            min: 0,
            max: 100,
            style: { textAlign: "center" },
          },
        }}
        style={{ marginBottom: "20px" }}
      />
    </CustomInputDialog>
  );
}

export default CashbackUpdateModalUI;
