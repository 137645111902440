import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import { Box } from "@mui/system";
import GeneralButton from "../../components/atoms/buttons/GeneralButton";
import InfoIconButton from "../../components/molecules/dialogues/info";
import { handleInputChange } from "../../functions/general/setData";
import GeneralInput from "../../components/atoms/inputs/GeneralInput";

const RegisterControlTab = ({ onSignUp }) => {
  const [accountInfo, setAccountInfo] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");

  return (
    <Card
      sx={{
        maxWidth: 380,
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 3,
        backgroundColor: "white",
        boxShadow: "1px 1px 20px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <CardContent sx={{ width: "100%", textAlign: "center" }}>
        <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
          Register Control
        </Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Manage your store's register apps. Set up access for your employees to
          handle payments seamlessly using Clavaa.
        </Typography>

        <Box sx={{ display: "grid", gap: 2 }}>
          <GeneralInput
            type={"text"}
            label="Username"
            name={"username"}
            value={accountInfo.username}
            onChange={(e) => handleInputChange(e, setAccountInfo)}
            error={!!error.username}
            errorMessageAndhelp={error.username || ""}
          />
          <GeneralInput
            type={"password"}
            label="Password"
            name={"password"}
            value={accountInfo.password}
            onChange={(e) => handleInputChange(e, setAccountInfo)}
            error={!!error.password}
            errorMessageAndhelp={error.password || ""}
          />
        </Box>
        <GeneralButton
          label="Submit"
          onClick={() =>
            onSignUp(accountInfo.username, accountInfo.password, setError)
          }
          type="contained"
          center={true}
        />
      </CardContent>
      <InfoIconButton
        title={"Register App Help"}
        info="Once you set up a username and password, you and your employees will
            be able to log into the register in-store. This allows for efficient
            and secure payment processing with Clavaa, enhancing your customer
            service experience."
      />
    </Card>
  );
};

export default RegisterControlTab;
