export function validateData(structure, inputData) {
    for (const key in structure) {
      const { type, required, structure: subStructure } = structure[key];
      const value = inputData[key];
  
      if (required && !(key in inputData)) {
        return { message: { error: `${key} is required` }, status: 400 };
      }
  
      if (key in inputData) {
        switch (type) {
          case "string":
            if (typeof value !== "string") return { message: { error: `${key} must be a string` }, status: 400 };
            break;
          case "number":
            if (typeof value !== "number") return { message: { error: `${key} must be a number` }, status: 400 };
            break;
          case "date":
            if (isNaN(Date.parse(value))) return { message: { error: `${key} must be a valid date` }, status: 400 };
            break;
          case "base64":
            if (!/^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(value))
              return { message: { error: `${key} must be a valid base64 string` }, status: 400 };
            break;
          case "object":
            const nestedValidation = validateData(subStructure, value);
            if (nestedValidation) return nestedValidation;
            break;
          case "array":
            if (!Array.isArray(value)) return { message: { error: `${key} must be an array` }, status: 400 };
            for (let i = 0; i < value.length; i++) {
              const arrayItemValidation = validateData({ arrayItem: structure[key].structure }, { arrayItem: value[i] });
              if (arrayItemValidation) return arrayItemValidation;
            }
            break;
          default:
            return { message: { error: `${key} has an unsupported type` }, status: 400 };
        }
      }
    }
    return null;
  }
  