// OutlinedButton.js
import React from "react";
import Button from "@mui/material/Button";

const OutlinedButton = ({ label, onClick, disabled, importedSx,fullWidth  }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      fullWidth={fullWidth}
 
      disabled={disabled}
      sx={{
        ...importedSx,
        mt: 1,
        mb: 1,
        display: "block",
        fontFamily: "SF Pro Display, Arial, sans-serif",
        fontSize: "0.875rem",
        fontWeight: 500,
        textTransform: "none",
        borderColor: "#44C952",
        color: "#44C952",
        "&:hover": {
          borderColor: "#3da843",
          color: "#3da843",
          backgroundColor: "rgba(61,168,67,0.1)", // Add a subtle green background
        },
        "&:disabled": {
          borderColor: "#aeaeae",
          color: "#aeaeae",
        },
        borderRadius: "4px",
      }}
    >
      {label}
    </Button>
  );
};

export default OutlinedButton;
