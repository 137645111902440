import masterFakeEndpoint from "../structure";

// Simulate the create-merchant-account API
export async function fakeCreateMerchantAccount(data) {
  // Define the expected data structure for this endpoint
  const dataStructure = {
    username: { type: "string", required: true },
    password: { type: "string", required: true },
    email: { type: "string", required: true },
  };

  // Define potential errors
  const errorArray = [
    { error: { username: "Username already exists." }, status: 400 },
    { error: { error: "Invalid email address." }, status: 400 },
    { error: { error: "An error occurred on our end." }, status: 400 },
  ];

  // Define the success output
  const successOutput = {
    token: "fake-jwt-token", // Simulate a JWT token
  };

  // Use the masterFakeEndpoint function to process the request
  return await masterFakeEndpoint(
    dataStructure,
    data,
    errorArray,
    successOutput
  );
}
